import React from "react";
import CallToAction from "../Components/CallToAction";
import FAQ from "../Components/FAQ";
import Features from "../Components/Features";

const Callback = () => {
  return (
    <div className="min-h-screen bg-white">
      <CallToAction />
      <Features />
      <FAQ />
    </div>
  );
};

export default Callback;
