import React from 'react';
import { Clock as ClockIcon, Shield as ShieldIcon, Mic as MicIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
interface Props {
    userName: string;
    serviceName: string;
}

const WelcomeCard: React.FC<Props> = ({ userName, serviceName }) => {
    const { t } = useTranslation();
    return (
        <div className="h-full p-8">
            <div className="space-y-6">
                <div>
                    <div className="w-16 h-16 bg-gradient-to-br from-teal-400 to-blue-500 rounded-full mb-4" />
                    <h1 className="text-xl text-gray-700">{t('welcomeCard.userName', { userName })}</h1>
                    <h2 className="text-2xl font-bold text-gray-900">{serviceName}</h2>
                </div>

                <div className="flex items-center gap-2">
                    <ClockIcon className="w-5 h-5 text-green-800" />
                    <span className="text-lg font-medium">{t('welcomeCard.15min')}</span>
                </div>

                <p className="text-gray-600">
                    {t('welcomeCard.serviceName', {
                        serviceName: serviceName
                    })}
                </p>

                <div className="space-y-4">
                    <div className="flex items-center gap-3">
                        <div className="w-8 h-8 rounded-lg bg-green-100 flex items-center justify-center">
                            <MicIcon className="w-4 h-4 text-green-800" />
                        </div>
                        <span className="text-gray-700">{t('welcomeCard.voiceActivated')}</span>
                    </div>


                    <div className="flex items-center gap-3">
                        <div className="w-8 h-8 rounded-lg bg-green-100 flex items-center justify-center">
                            <svg className="w-4 h-4 text-green-800" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <path d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                            </svg>
                        </div>
                        <span className="text-gray-700">{t('welcomeCard.privateSpace')}</span>
                    </div>

                    <div className="flex items-center gap-3">
                        <div className="w-8 h-8 rounded-lg bg-green-100 flex items-center justify-center">
                            <ShieldIcon className="w-4 h-4 text-green-800" />
                        </div>
                        <span className="text-gray-700">{t('welcomeCard.secure')}</span>
                    </div>

                </div>

                <div className="pt-4">
                    <p className="text-sm text-gray-500">
                            {t('welcomeCard.emergency')}
                    </p>
                </div>

                <p className="text-gray-600">
                    {t('welcomeCard.message',{serviceName})}
                </p>
            </div>
        </div>
    );
};

export default WelcomeCard;
