import { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from '../hooks/useSubscription.js';

const SubscriptionGuard = memo(({ children }) => {
  const { isValid, isLoading, error, subscription, isInitialized } = useSubscription();
  const navigate = useNavigate();

  useEffect(() => {
    // Only redirect if we've finished loading and the subscription is explicitly not valid
    if (!isLoading && isValid === false && isInitialized) {
      navigate('/pricing', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, isLoading, error, subscription, isInitialized]);

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-pulse text-center">
          <div className="h-8 bg-gray-200 rounded w-48 mb-4"></div>
          <div className="h-4 bg-gray-200 rounded w-32"></div>
        </div>
      </div>
    );
  }

  // Don't render anything while redirecting
  if (!isValid) {
    return null;
  }

  return children;
});

SubscriptionGuard.displayName = 'SubscriptionGuard';

SubscriptionGuard.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubscriptionGuard;
