import React, { useCallback, useContext, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Home, FileText, FileSignature } from 'lucide-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { RootContext } from '../rootContext.jsx';

const useNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return useCallback(
    (path) => {
      if (location.pathname === path) return;
      navigate(path, {
        replace: true,
        state: {
          preserveSidebar: true,
          from: location.pathname,
        },
      });
    },
    [navigate, location.pathname],
  );
};

const SidebarContent = React.memo(({ reportId, currentPath, onNavigate, language }) => {
  const { t } = useTranslation();
  const { setIsSidebarOpen } = useContext(RootContext);
  const items = useMemo(
    () => [
      {
        icon: <FileText />,
        label: t('navigation.intakeReport'),
        path: reportId ? `/auth/report/${reportId}` : '/auth/report',
      },
      {
        icon: <Home />,
        label: t('navigation.scheduleFollowup'),
        path: '/auth/dashboard',
        requiresSubscription: true,
      },
      {
        icon: <FileSignature />,
        label: t('navigation.followupReport'),
        path: '/auth/followup',
        requiresSubscription: true,
      },
      // {
      //   icon: <Target />,
      //   label: t('navigation.goals'),
      //   path: '/auth/goals',
      //   requiresSubscription: true,
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportId, language],
  );

  const handleNavigate = (path) => {
    onNavigate(path);
    setIsSidebarOpen(false);
  };

  return (
    <div className="space-y-2 mt-10">
      {items.map((item, index) => (
        <button
          key={index}
          onClick={() => handleNavigate(item.path)}
          disabled={!item.path}
          className={`
            w-full text-left px-4 py-3 rounded-xl transition-all duration-300 ease-in-out
            flex items-center group
            ${
              currentPath === item.path
                ? 'bg-gradient-to-r from-teal-500/20 to-teal-500/10 text-teal-700 shadow-md'
                : 'text-gray-600 hover:bg-gradient-to-r hover:from-teal-500/10 hover:to-teal-500/20 hover:shadow-sm'
            }
            ${!item.path ? 'opacity-50 cursor-not-allowed' : 'hover:translate-x-1'}
          `}
        >
          <span
            className={`
              mr-4 text-xl transition-all duration-300
              ${currentPath === item.path ? 'text-teal-600 scale-110' : 'text-gray-400 group-hover:text-teal-500 group-hover:scale-110'}
            `}
          >
            {item.icon}
          </span>
          <span className="text-sm font-medium tracking-wide">{item.label}</span>
          {currentPath === item.path && (
            <span className="ml-auto text-teal-600">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          )}
        </button>
      ))}
    </div>
  );
});

SidebarContent.propTypes = {
  reportId: PropTypes.string,
  currentPath: PropTypes.string.isRequired,
  onNavigate: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
};

SidebarContent.displayName = 'SidebarContent';

export const Sidebar = React.memo(
  ({ reportId, isOpen, language }) => {
    const location = useLocation();
    const handleNavigation = useNavigation();
    return (
      <>
        <div
          className={`fixed md:static top-0 left-0 h-full w-72 bg-white shadow-lg border-r border-gray-100 transform transition-transform duration-300 ease-in-out z-40 mt-2
          ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0`}
        >
          <div className="p-6">
            <SidebarContent reportId={reportId} currentPath={location.pathname} onNavigate={handleNavigation} language={language} />
          </div>
        </div>
      </>
    );
  },
  (prevProps, nextProps) => prevProps.isOpen === nextProps.isOpen && prevProps.language === nextProps.language,
);

Sidebar.propTypes = {
  reportId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
};

Sidebar.displayName = 'Sidebar';

export default Sidebar;
