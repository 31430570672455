import { useEffect } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import { generateTawkHash } from '../lib/tawkUtils.js';

/**
 * Tawk.to Live Chat Widget Component
 * This component loads the Tawk.to live chat widget script and initializes it.
 * The widget will appear in the bottom right corner of the page.
 * Uses Tawk.to JS API to identify users and track conversations.
 * Implements secure mode to authenticate visitor data.
 */
const TawkToChat = () => {
  const { user } = useUser();
  const { userId, isLoaded, isSignedIn } = useAuth();

  // This effect handles the Tawk.to user identification when auth state changes
  useEffect(() => {
    // Skip if not loaded or if Tawk API is not ready
    if (!isLoaded || !window.Tawk_API || !window.Tawk_API.setAttributes) {
      return;
    }

    // If user is signed in, set their attributes for tracking
    if (isSignedIn && user && userId) {
      const email = user.primaryEmailAddress?.emailAddress || '';
      const name = `${user.firstName || ''} ${user.lastName || ''}`.trim();

      // Generate the hash for secure mode
      const hash = generateTawkHash(email);
      // Set visitor information with secure mode hash
      window.Tawk_API.visitor = {
        name,
        email,
        hash, // This hash authenticates the visitor data
      };

      // Set additional attributes
      window.Tawk_API.setAttributes(
        {
          id: userId,
          email,
          name,
        },
        function handleAttributesCallback(error) {
          if (error) {
            // eslint-disable-next-line no-console
            console.error('Error setting Tawk user attributes:', error);
          }
        },
      );
    } else if (!isSignedIn && window.Tawk_API.visitor) {
      // If user logs out, reset the visitor data
      window.Tawk_API.visitor = {
        name: null,
        email: null,
        hash: null,
      };
    }
  }, [user, userId, isLoaded, isSignedIn]);

  // This effect loads the Tawk.to script
  useEffect(() => {
    // Initialize Tawk_API and set the loading start time
    window.Tawk_API = window.Tawk_API || {};
    window.Tawk_LoadStart = new Date();

    // Set up event handlers for when the widget is ready
    window.Tawk_API.onLoad = function handleTawkLoad() {
      // The widget has loaded
    };

    // Create and append the Tawk.to script
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://embed.tawk.to/66840fe19d7f358570d630d7/1i1pts2sr';
    script.charset = 'UTF-8';
    script.setAttribute('crossorigin', '*');

    // Add the script to the document
    const firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript);

    // Clean up function to remove the script when component unmounts
    return () => {
      // Only remove if the script exists
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }

      // Hide the widget if it's been loaded
      if (window.Tawk_API && window.Tawk_API.hideWidget) {
        window.Tawk_API.hideWidget();
      }
    };
  }, []);

  // This component doesn't render anything visible
  return null;
};

export default TawkToChat;
