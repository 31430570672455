import { useState, useCallback, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useAxios } from '../Components/axiosContext.js';

export const useReportId = () => {
  const { user } = useUser();
  const axios = useAxios();
  const [reportId, setReportId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const userReportId = user?.unsafeMetadata?.reportId;

  const fetchReportId = useCallback(async () => {
    if (!axios || !user) return;
    setIsLoading(true);
    try {
      const response = await axios.get('calls/reports', {
        params: {
          limit: 1,
          offset: 0,
          reportType: 'INTAKE',
        },
      });
      const newReportId = response?.data?.data[0]?.id;
      if (newReportId) {
        setReportId(newReportId);
        await user.update({
          unsafeMetadata: {
            ...user?.unsafeMetadata,
            ...(newReportId ? { reportId: newReportId } : {}),
          },
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching report ID:', error);
    } finally {
      setIsLoading(false);
    }
  }, [axios, user]);

  useEffect(() => {
    if (axios && !reportId && !userReportId) {
      fetchReportId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, userReportId]);

  const effectiveReportId = userReportId || reportId;

  return {
    reportId: effectiveReportId,
    isLoading,
    fetchReportId,
  };
};
