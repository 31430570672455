import { RedirectToSignIn, useAuth } from '@clerk/clerk-react';
import { Outlet } from 'react-router-dom';
import Loading from './Components/Loading.jsx';

const AuthedLayout = () => {
  const { userId, isLoaded } = useAuth();

  if (!isLoaded) return <Loading />;
  if (!userId) return <RedirectToSignIn />;

  return (
    <div className="flex w-full flex-col h-screen">
      <div className="flex-1 overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default AuthedLayout;
