import React from 'react';
import { format, startOfWeek, endOfWeek, isWithinInterval, addMinutes } from 'date-fns';
import { Clock, Calendar, ArrowRight } from 'lucide-react';
import { toZonedTime } from 'date-fns-tz';
import { useTranslation } from 'react-i18next';

interface Call {
  id: string;
  scheduledAt: Date;
  startTime: string;
  endTime: string;
  status: 'SCHEDULED' | 'COMPLETED' | 'MISSED' | 'PENDING';
  callType: string;
  recurringPattern: string;
}

interface Props {
  events: Call[];
  timezone: string;
}

const UpcomingCalls: React.FC<Props> = ({ events, timezone }) => {
  const today = new Date();
  const {t} = useTranslation();
  const weekStart = startOfWeek(today, { weekStartsOn: 1 }); // Start from Monday
  const weekEnd = endOfWeek(today, { weekStartsOn: 1 });
  const thisWeeksEvents = events
    .filter((event) => {
      const date = new Date(event.scheduledAt);
      // isWithinInterval requires a Date object
      return isWithinInterval(date, { start: weekStart, end: weekEnd }) && (event.status === 'SCHEDULED' || event.status === 'PENDING');
    })
    .sort((a, b) => {
      // Convert both to Date so we can do getTime()
      const dateA = new Date(a.scheduledAt);
      const dateB = new Date(b.scheduledAt);
      return dateA.getTime() - dateB.getTime();
    });

  const formatTimeRange = (startTime: Date) => {
    const start = toZonedTime(startTime, timezone);
    const end = addMinutes(start, 15);
    return `${format(start, 'h:mm a')} - ${format(end, 'h:mm a')}`;
  };

  return (
    <div className="bg-white rounded-2xl shadow-lg p-6">
      <div className="flex items-center justify-between mb-6">
        <h2 className="text-xl font-semibold text-gray-900">{t("upcomingCalls.thisWeek")}</h2>
        <Calendar className="w-5 h-5 text-green-800" />
      </div>

      <div className="space-y-4">
        {thisWeeksEvents.length === 0 ? (
          <p className="text-gray-500 text-center py-4">{t("upcomingCalls.upcomingEvents")}</p>
        ) : (
          thisWeeksEvents.map((event) => (
            <div key={event.id} className="flex items-center gap-4 p-4 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors">
              <div className="flex-shrink-0 w-12 h-12 bg-green-100 rounded-lg flex items-center justify-center">
                <span className="text-lg font-bold text-green-800">{format(toZonedTime(event.scheduledAt, timezone), 'd')}</span>
              </div>

              <div className="flex-grow">
                <div className="flex items-center gap-2">
                  <span className="font-medium text-gray-900">{format(event.scheduledAt, 'EEEE')}</span>

                  {event.callType === 'recurring' && (
                    <span className="text-xs px-2 py-1 rounded-full bg-purple-100 text-purple-600">{event.recurringPattern}</span>
                  )}
                </div>
                <div className="flex items-center gap-1 text-sm text-gray-600">
                  <Clock className="w-4 h-4" />
                  <span>{formatTimeRange(event.scheduledAt)}</span>
                </div>
              </div>
              {/* <ArrowRight className="w-5 h-5 text-gray-400" /> */}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default UpcomingCalls;
