import { Typography } from '@material-tailwind/react';
import PropTypes from 'prop-types';
import { cn } from '../lib/utils.js';

const Loading = ({ className }) => {
  return (
    <div className={cn('max-w-full animate-pulse p-8', className)}>
      <Typography as="div" variant="h1" className="mb-4 h-3 w-56 rounded-full bg-gray-300">
        &nbsp;
      </Typography>
      <Typography as="div" variant="paragraph" className="mb-2 h-2 w-72 rounded-full bg-gray-300">
        &nbsp;
      </Typography>
      <Typography as="div" variant="paragraph" className="mb-2 h-2 w-72 rounded-full bg-gray-300">
        &nbsp;
      </Typography>
      <Typography as="div" variant="paragraph" className="mb-2 h-2 w-72 rounded-full bg-gray-300">
        &nbsp;
      </Typography>
      <Typography as="div" variant="paragraph" className="mb-2 h-2 w-72 rounded-full bg-gray-300">
        &nbsp;
      </Typography>
    </div>
  );
};

Loading.propTypes = {
  className: PropTypes.string,
};

export default Loading;
