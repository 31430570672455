import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UnauthenticatedAxiosProvider } from '../Components/UnauthenticatedAxiosProvider.jsx';
import { useAxios } from '../Components/axiosContext.js';
import Toast from '../Components/Toast.jsx';

// Component that uses useAxios hook
const OrganizationInviteContent = () => {
  const { slug } = useParams();
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
    role: 'org:member',
    redirectUrl: window.location.href,
  });
  const [status, setStatus] = useState('idle'); // idle, loading, success, error
  const [organizationName, setOrganizationName] = useState('');
  const [organizationLogo, setOrganizationLogo] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showToast, setShowToast] = useState(false);
  const [organizationNotFound, setOrganizationNotFound] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    message: '',
    variant: 'success',
  });
  const axios = useAxios();

  // Fetch organization name
  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      try {
        setIsLoading(true);
        // This is just a placeholder - you would need to implement this endpoint
        const response = await axios.get(`organizations/details/${slug}`);
        if (response.data?.name) {
          setOrganizationName(response.data.name);
          setOrganizationLogo(response.data.imageUrl);
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch organization details:', err);
        setOrganizationNotFound(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrganizationDetails();
  }, [slug, axios]);

  // Validate form
  const isValid = () => {
    return formData.email;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid()) return;

    setStatus('loading');

    try {
      const response = await axios.post(`organizations/invite/${slug}`, formData);
      if (response.data.success) {
        setStatus('success');
        setToastMessage({
          message: t('organization.invite.success'),
          variant: 'success',
        });
        setShowToast(true);
      } else {
        throw new Error(response.data.error || 'Failed to send invitation');
      }
    } catch (err) {
      setStatus('error');
      let errorMessage = t('organization.invite.error');
      if (err.response.data.error) {
        errorMessage = err.response.data.error;
        setToastMessage({
          message: Array.isArray(errorMessage.errors) ? errorMessage.errors[0].message : errorMessage,
          variant: 'error',
        });
        setShowToast(true);
      }
    }
  };

  // organization not found
  if (organizationNotFound) {
    return (
      <div className="min-h-screen bg-gradient-to-br to-emerald-100 py-12 px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center mt-10">
        <div className="max-w-md w-full mx-auto bg-white rounded-2xl shadow-xl overflow-hidden transition-all duration-300 transform hover:shadow-2xl">
          <div className="h-2 bg-[#2B513B]"></div>
          <div className="p-8 text-center">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">Organization not found</h1>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br to-emerald-100 py-12 px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center mt-10">
      <div className="max-w-md w-full mx-auto bg-white rounded-2xl shadow-xl overflow-hidden transition-all duration-300 transform hover:shadow-2xl">
        <div className="h-2 bg-[#2B513B]"></div>
        <div className="p-8">
          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-[#2B513B]"></div>
            </div>
          ) : (
            <>
              <div className="text-center mb-8">
                <div className="inline-flex items-center justify-center h-16 w-16 rounded-full bg-green-100 text-[#2B513B] mb-4">
                  {organizationLogo ? (
                    <img src={organizationLogo} alt="Organization Logo" className="h-15 w-15 rounded-full" />
                  ) : (
                    <svg className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                      />
                    </svg>
                  )}
                </div>
                <h2 className="text-2xl font-extrabold text-gray-800 mb-1">
                  {organizationName ? t('organization.invite.titleWithName', { name: organizationName }) : t('organization.invite.title')}
                </h2>
                <p className="text-gray-600 mb-6">{t('organization.invite.subtitle')}</p>
                <div className="h-px w-16 bg-green-200 mx-auto mb-6"></div>
              </div>

              {status === 'success' ? (
                <div className="text-center animate-fade-in">
                  <div className="bg-green-50 border border-green-100 p-6 rounded-xl mb-6">
                    <div className="w-16 h-16 mx-auto bg-green-100 rounded-full flex items-center justify-center mb-4">
                      <svg className="w-8 h-8 text-[#2B513B]" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                      </svg>
                    </div>
                    <h3 className="text-xl font-semibold text-[#2B513B] mb-2">{t('organization.invite.success')}</h3>
                    <p className="text-green-700">{t('organization.invite.successMessage')}</p>
                  </div>
                  <button
                    onClick={() => {
                      setStatus('idle');
                      setFormData({ email: '' });
                    }}
                    className="inline-flex items-center px-5 py-2.5 rounded-lg bg-[#2B513B] text-white font-medium hover:bg-[#234432] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-all duration-200 transform hover:-translate-y-1"
                  >
                    <svg className="w-4 h-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path>
                    </svg>
                    {t('organization.invite.sendAnother')}
                  </button>
                </div>
              ) : (
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                      {t('common.email')}
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <svg className="h-5 w-5 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                          />
                        </svg>
                      </div>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder={t('common.emailPlaceholder')}
                        className="block w-full pl-10 pr-3 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#2B513B] focus:border-[#2B513B] text-gray-900 placeholder-gray-400 transition-colors duration-200"
                      />
                    </div>
                  </div>

                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-gray-200"></div>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      disabled={status === 'loading' || !isValid()}
                      className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-[#2B513B] hover:bg-[#2B513B]/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2B513B] ${
                        status === 'loading' ? 'cursor-not-allowed opacity-70' : ''
                      }`}
                    >
                      {status === 'loading' ? (
                        <>
                          <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            ></path>
                          </svg>
                          {t('common.sending')}
                        </>
                      ) : (
                        <>
                          <svg className="w-5 h-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
                            />
                          </svg>
                          {t('organization.invite.sendInvitation')}
                        </>
                      )}
                    </button>
                  </div>
                </form>
              )}
            </>
          )}
        </div>
      </div>

      {/* Small logo or branding at the bottom */}
      <div className="mt-8 text-center">
        <p className="text-xs text-gray-500">
          © {new Date().getFullYear()} Hana • {t('common.secureInvitation')}
        </p>
      </div>

      {/* Toast Notification */}
      {showToast && <Toast message={toastMessage.message} variant={toastMessage.variant} setShowToast={setShowToast} />}
    </div>
  );
};

// Main component that provides the axios context
const OrganizationInvite = () => {
  return (
    <UnauthenticatedAxiosProvider>
      <OrganizationInviteContent />
    </UnauthenticatedAxiosProvider>
  );
};

export default OrganizationInvite;
