import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { X, Calendar, Clock, RotateCw, Loader } from 'lucide-react';
import { format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

const EventModal = ({ isOpen, onClose, event = null, onSave, onDelete = undefined, selectedDate = new Date(), selectedTimezone, loading = false }) => {
  const [date, setDate] = useState(event?.date || selectedDate);
  const [startTime, setStartTime] = useState(event?.startTime || '');
  const [callType, setCallType] = useState(event?.recurringSchedule?.id ? 'recurring' : 'one-time');
  const [recurringType, setRecurringType] = useState(event?.recurringSchedule?.frequency || '');
  const [editMode, setEditMode] = useState('single');
  const [error, setError] = useState('');

  useEffect(() => {
    if (event) {
      // Editing an existing event
      const scheduledDate = event.scheduledAt instanceof Date ? event.scheduledAt : parseISO(event.scheduledAt);
      const scheduledDateInTimezone = toZonedTime(scheduledDate, selectedTimezone?.value || 'UTC');
      setDate(scheduledDateInTimezone);

      const timeString = format(scheduledDateInTimezone, 'HH:mm');
      setStartTime(timeString);
      setCallType(event?.recurringSchedule?.id ? 'recurring' : 'one-time');
      setRecurringType(event?.recurringSchedule?.frequency || 'WEEKLY');

      // If event is recurring, default editMode to "all" or "single"
      if (event?.recurringSchedule?.id) {
        setEditMode('all');
      } else {
        setEditMode('single');
      }
    } else {
      // Creating a new event
      const isRecurringDate = event?.recurringSchedule?.id;
      if (isRecurringDate) {
        const recurringDate = parseISO(event?.recurringSchedule?.startDate);
        setDate(recurringDate);
        setStartTime(toZonedTime(recurringDate, selectedTimezone?.value || 'UTC'));
        setCallType('recurring');
        setRecurringType(event?.recurringSchedule?.frequency);
      } else {
        setDate(selectedDate);
        setStartTime('');
        setCallType('one-time');
        setRecurringType('');
      }
      setEditMode('single');
    }
  }, [event, selectedDate, selectedTimezone]);

  // Add a new useEffect to handle modal close
  useEffect(() => {
    if (!isOpen) {
      // Reset all state when modal closes
      setDate(selectedDate);
      setStartTime('');
      setCallType('one-time');
      setRecurringType('');
      setEditMode('single');
      setError('');
    }
  }, [isOpen, selectedDate]);

  // -----------------------------------------------
  // 3. Handle Save
  // -----------------------------------------------
  const handleSave = () => {
    if (!date || !startTime) {
      if (!date && !startTime) {
        setError('Please select both date and start time.');
      } else if (!date) {
        setError('Please select a date.');
      } else if (!startTime) {
        setError('Please select a start time.');
      }
      return;
    }

    if (callType === 'recurring') {
      if (!recurringType && !editMode) {
        setError('Please select a recurring type and edit mode.');
      } else if (!recurringType) {
        setError('Please select a recurring type (weekly or monthly).');
      } else if (!editMode) {
        setError('Please select an edit mode.');
      }

      if (!recurringType || !editMode) {
        return;
      }
    } else {
      setError('');
    }

    const [hoursStr, minutesStr] = startTime.split(':');
    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const baseData = {
      date,
      callType,
      hours,
      minutes,
      editMode,
      id: event?.id ?? null,
      recurringScheduleId: event?.recurringSchedule?.id ?? null,
    };

    if (callType === 'one-time') {
      onSave(baseData);
    } else if (callType === 'recurring') {
      const eventData = {
        ...baseData,
        recurringPattern: recurringType
          ? {
              type: recurringType,
              dayOfWeek: date.getDay(),
              dayOfMonth: date.getDate(),
            }
          : undefined,
      };
      onSave(eventData, editMode);
    }
    onClose();
  };

  // -----------------------------------------------
  // 4. Handle Delete
  // -----------------------------------------------
  const handleDelete = () => {
    if (event && onDelete) {
      let obj = event;
      obj = {
        ...obj,
        recurrenceType: recurringType,
        editMode,
        callType,
        date,
      };
      onDelete(obj, editMode);
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div
      className="
    fixed inset-0 flex items-center justify-center z-50
    bg-gray-800 bg-opacity-60
    transition-opacity duration-300
    animate-fadeInBackdrop
  "
    >
      <div
        className="
      w-full max-w-md p-6
      bg-white border border-gray-200
      rounded-xl shadow-xl
      transform transition-all duration-300 ease-in-out
      animate-modalOpen
    "
      >
        {/* Header */}
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-semibold tracking-wide text-gray-900">{event ? 'Edit Event' : 'New Event'}</h2>
          <button
            onClick={onClose}
            className="
          text-gray-500 hover:text-gray-700
          transition-transform duration-150 transform hover:scale-110
          focus:outline-none focus:ring-2 focus:ring-indigo-500 rounded
        "
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Body */}
        <div className="space-y-4 text-gray-700">
          {/* Date */}
          <div>
            <label className="block text-sm font-medium mb-1">
              <Calendar className="w-4 h-4 inline-block mr-2" />
              Date
            </label>
            <input
              type="date"
              value={date instanceof Date && !Number.isNaN(date.getTime()) ? format(date, 'yyyy-MM-dd') : ''}
              onChange={(e) => setDate(new Date(e.target.value))}
              className="
            w-full border border-gray-300 rounded-md p-2
            focus:outline-none focus:ring-2 focus:ring-indigo-500
            transition-colors
          "
            />
          </div>

          {/* Start Time */}
          <div>
            <label className="block text-sm font-medium mb-1">
              <Clock className="w-4 h-4 inline-block mr-2" />
              Start Time
            </label>
            <div className="flex items-center gap-2">
              <input
                type="time"
                value={startTime}
                onChange={(e) => setStartTime(e.target.value)}
                className="
              flex-1 border border-gray-300 rounded-md p-2
              focus:outline-none focus:ring-2 focus:ring-indigo-500
              transition-colors
            "
              />
              <span className="text-sm text-gray-500 italic">(15 min duration)</span>
            </div>
          </div>

          {/* Call Type / Recurring */}
          <div>
            <label className="block text-sm font-medium mb-1">
              <RotateCw className="w-4 h-4 inline-block mr-2" />
              Repeat
            </label>
            <select
              value={callType}
              onChange={(e) => {
                const selected = e.target.value;
                setCallType(selected);
                if (selected === 'one-time') {
                  setRecurringType('');
                  setEditMode('single');
                } else if (selected === 'recurring') {
                  setEditMode('all');
                }
              }}
              className="
            w-full border border-gray-300 rounded-md p-2
            focus:outline-none focus:ring-2 focus:ring-indigo-500
            transition-colors
          "
            >
              <option value="one-time">One-time</option>
              <option value="recurring">Recurring</option>
            </select>
          </div>

          {/* Recurring Options */}
          {callType === 'recurring' && (
            <>
              <div>
                <label className="block text-sm font-medium mb-1">Recurring Type</label>
                <select
                  value={recurringType}
                  onChange={(e) => setRecurringType(e.target.value)}
                  className="
                w-full border border-gray-300 rounded-md p-2
                focus:outline-none focus:ring-2 focus:ring-indigo-500
                transition-colors
              "
                >
                  <option value="">Select...</option>
                  <option value="WEEKLY">Weekly</option>
                  <option value="MONTHLY">Monthly</option>
                </select>
              </div>
              {event && (
                <div>
                  <label className="block text-sm font-medium mb-1">Edit Mode</label>
                  <select
                    value={editMode}
                    onChange={(e) => setEditMode(e.target.value)}
                    className="
                w-full border border-gray-300 rounded-md p-2
                focus:outline-none focus:ring-2 focus:ring-indigo-500
                transition-colors
              "
                  >
                    <option value="single">This event only</option>
                    <option value="all">All events in series</option>
                  </select>
                </div>
              )}
            </>
          )}

          {/* Error Message */}
          {error && <div className="text-sm text-rose-600 border-l-4 border-rose-400 pl-2 py-1">{error}</div>}

          {/* Action Buttons */}
          <div className="flex justify-end gap-4 mt-6">
            {event && (
              <button
                onClick={handleDelete}
                className="
              px-4 py-2 text-rose-600 border border-rose-600
              hover:bg-rose-600 hover:text-white rounded-md
              transition-transform duration-150 transform hover:scale-105
              shadow-sm focus:outline-none focus:ring-2 focus:ring-rose-400
            "
              >
                Delete
              </button>
            )}
            <button
              onClick={handleSave}
              disabled={loading}
              className="
            px-4 py-2 rounded-md bg-[#2B513B] hover:bg-[#2B513B]
            text-white font-medium transition-transform duration-150 transform hover:scale-105
            shadow-sm focus:outline-none focus:ring-2 focus:ring-[#2B513B]
          "
            >
              {loading ? 'Saving...' : 'Save'}
              {loading && <Loader className="w-4 h-4 inline-block ml-2 animate-spin" />}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

EventModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  event: PropTypes.shape({
    id: PropTypes.string,
    date: PropTypes.instanceOf(Date),
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    status: PropTypes.oneOf(['SCHEDULED', 'PENDING', 'COMPLETED', 'MISSED']),
    callType: PropTypes.oneOf(['one-time', 'recurring']),
    recurringPattern: PropTypes.shape({
      type: PropTypes.oneOf(['weekly', 'monthly']),
    }),
    editMode: PropTypes.oneOf(['single', 'all']),
  }),
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  selectedDate: PropTypes.instanceOf(Date),
  selectedTimezone: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }),
  loading: PropTypes.bool,
};

export default EventModal;
