import PropTypes from 'prop-types';
import { cn } from '../lib/utils.js';

const LoadingSpinner = ({ className }) => <div className={cn('animate-spin rounded-full border-2 border-gray-300 border-t-teal-500', className)} />;

LoadingSpinner.propTypes = {
  className: PropTypes.string,
};

export default LoadingSpinner;
