import { CheckCircle2, Calendar } from 'lucide-react';
import { useState, useEffect } from 'react';
import { useSearchParams, Link } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { useAxios } from '../Components/axiosContext.js';
import { formatDate, formatPrice } from '../lib/formatUtils.js';

const PaymentSuccess = () => {
  const axios = useAxios();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const { user, isLoaded: isUserLoaded } = useUser();
  const sessionId = searchParams.get('session_id');

  useEffect(() => {
    let isMounted = true;

    const fetchCheckoutSession = async () => {
      if (!sessionId || !user) return;

      try {
        const { data } = await axios.get(`/payments/checkout-session/${sessionId}`);

        if (!data?.subscription) {
          throw new Error('No subscription data found');
        }

        if (isMounted) {
          setPaymentDetails(data);
          // Update user metadata with subscription info
          await user.update({
            unsafeMetadata: {
              ...user.unsafeMetadata,
              stripeCustomerId: data?.customer?.id,
              subscription: {
                id: data?.subscription?.id,
                status: data?.subscription?.status,
                currentPeriodEnd: data.subscription.current_period_end,
                plan: {
                  id: data.subscription.plan.id,
                  amount: data.subscription.plan.amount,
                  interval: data.subscription.plan.interval,
                },
                discount: data.subscription.discount
                  ? {
                      name: data.subscription.discount.name,
                      percentOff: data.subscription.discount.percent_off,
                      durationInMonths: data.subscription.discount.duration_in_months,
                    }
                  : null,
              },
            },
          });
        }
      } catch (err) {
        if (isMounted) {
          setError('Failed to load subscription details. Please contact support.');
          // eslint-disable-next-line no-console
          console.error('Error fetching checkout session:', err);
        }
      } finally {
        if (isMounted) {
          setLoading(false);
        }
      }
    };

    fetchCheckoutSession();

    return () => {
      isMounted = false;
    };
  }, [sessionId, user, axios]);

  // Show loading state while user authentication is being checked
  if (!isUserLoaded || loading) {
    return (
      <div className="min-h-screen bg-[#F5F8F6] py-12">
        <div className="max-w-3xl mx-auto px-4">
          <div className="animate-pulse space-y-8">
            <div className="h-8 bg-[#2B513B]/10 rounded w-1/3 mx-auto"></div>
            <div className="space-y-4">
              <div className="h-4 bg-[#2B513B]/10 rounded w-3/4"></div>
              <div className="h-4 bg-[#2B513B]/10 rounded w-1/2"></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Show error if user is not authenticated
  if (!user) {
    return (
      <div className="min-h-screen bg-[#F5F8F6] py-12">
        <div className="max-w-3xl mx-auto px-4">
          <div className="bg-red-50 p-6 rounded-lg border border-red-200 text-center">
            <h2 className="text-red-700 text-xl font-semibold mb-2">Authentication Required</h2>
            <p className="text-red-600">Please sign in to view payment details.</p>
            <Link to="/sign-in" className="mt-4 inline-block text-[#2B513B] hover:underline">
              Sign In
            </Link>
          </div>
        </div>
      </div>
    );
  }

  // Show error if there was a problem loading payment details
  if (error) {
    return (
      <div className="min-h-screen bg-[#F5F8F6] py-12">
        <div className="max-w-3xl mx-auto px-4">
          <div className="bg-red-50 p-6 rounded-lg border border-red-200 text-center">
            <h2 className="text-red-700 text-xl font-semibold mb-2">Something went wrong</h2>
            <p className="text-red-600">{error}</p>
            <Link to="/" className="mt-4 inline-block text-[#2B513B] hover:underline">
              Return to Home
            </Link>
          </div>
        </div>
      </div>
    );
  }

  const { subscription } = paymentDetails;

  return (
    <div className="min-h-screen bg-[#F5F8F6] py-24">
      <div className="max-w-3xl mx-auto px-4">
        {/* Success Header */}
        <div className="text-center mb-12">
          <div className="inline-flex items-center justify-center w-16 h-16 bg-[#2B513B]/10 rounded-full mb-4">
            <CheckCircle2 className="w-8 h-8 text-[#2B513B]" />
          </div>
          <h1 className="text-3xl font-bold text-[#2B513B] mb-2">Payment Successful!</h1>
          <p className="text-gray-600">Thank you for subscribing. Your subscription is now active.</p>
        </div>

        {/* Payment Details Card */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden mb-8">
          <div className="p-6 border-b border-gray-200">
            <h2 className="text-xl font-semibold text-gray-900 mb-4">Subscription Details</h2>
            <div className="space-y-4">
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Status</span>
                <span className="capitalize px-2 py-1 rounded-full text-sm bg-green-100 text-green-800">{subscription.status}</span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Plan</span>
                <span className="font-semibold text-gray-900">
                  {formatPrice(subscription.plan.amount, subscription.plan.currency)} / {subscription.plan.interval}
                </span>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Billing Period</span>
                <div className="flex items-center">
                  <Calendar className="w-4 h-4 mr-2 text-gray-500" />
                  <span className="text-gray-900">
                    {subscription.plan.interval_count} {subscription.plan.interval}
                  </span>
                </div>
              </div>
              <div className="flex justify-between items-center">
                <span className="text-gray-600">Current Period</span>
                <span className="text-gray-900">
                  {formatDate(subscription.current_period_start * 1000)} - {formatDate(subscription.current_period_end * 1000)}
                </span>
              </div>
              {subscription.discount && (
                <div className="flex justify-between items-center">
                  <span className="text-gray-600">Applied Discount</span>
                  <div className="text-green-600">
                    <div className="text-right">
                      <span className="font-medium">{subscription.discount.name}</span>
                      <span className="ml-2">({subscription.discount.percent_off}% off)</span>
                    </div>
                    <div className="text-sm text-green-500">
                      {(() => {
                        if (subscription.discount.duration === 'forever') {
                          return 'Applied forever';
                        }
                        if (subscription.discount.duration === 'once') {
                          return 'Applied once';
                        }
                        if (subscription.discount.duration === 'repeating' && subscription.discount.duration_in_months) {
                          return `Applied for ${subscription.discount.duration_in_months} months`;
                        }
                        return 'Limited time offer';
                      })()}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Invoice Section */}
          <div className="p-6 border-t border-gray-200 bg-gray-50">
            <h3 className="text-lg font-medium text-gray-900 mb-4">Invoice & Payment Records</h3>
            <div className="space-y-4">
              {subscription?.latest_invoice?.hosted_invoice_url && (
                <a
                  href={subscription.latest_invoice.hosted_invoice_url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center justify-between p-3 bg-white rounded-lg border border-gray-200 hover:border-[#2B513B] transition-colors duration-200 group"
                >
                  <div className="flex items-center">
                    <div className="p-2 bg-[#2B513B]/5 rounded-lg group-hover:bg-[#2B513B]/10">
                      <CheckCircle2 className="w-5 h-5 text-[#2B513B]" />
                    </div>
                    <div className="ml-3">
                      <p className="text-sm font-medium text-gray-900">View Invoice Online</p>
                      <p className="text-xs text-gray-500">View your invoice in browser</p>
                    </div>
                  </div>
                  <div className="text-[#2B513B]">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </a>
              )}
              {/* Invoice Summary */}
              {subscription?.latest_invoice && (
                <div className="mt-4 space-y-3">
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Invoice Number</span>
                    <span className="text-gray-900 font-medium">{subscription.latest_invoice.number}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Invoice Date</span>
                    <span className="text-gray-900">{formatDate(subscription.latest_invoice.created * 1000)}</span>
                  </div>
                  <div className="flex justify-between text-sm">
                    <span className="text-gray-600">Amount</span>
                    <span className="text-gray-900 font-medium">
                      {formatPrice(subscription.latest_invoice.amount_paid, subscription.latest_invoice.currency)}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Navigation Links */}
          <div className="p-6 border-t border-gray-200">
            <Link
              to="/auth/dashboard"
              className="block w-full bg-[#2B513B] text-white text-center py-3 px-4 rounded-lg hover:bg-[#234331] transition-colors duration-300"
            >
              Go to Dashboard
            </Link>
            <p className="text-center text-sm text-gray-500 mt-4">
              Need help?{' '}
              <a href="/support" className="text-[#2B513B] hover:underline">
                Contact support
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccess;
