import { useEffect, useState } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useParams, useNavigate } from 'react-router-dom';
import Loading from '../Components/Loading.jsx';
import EmailVerification from '../Components/verifyEmail.jsx';
import { Report } from '../Components/IntakeReport.jsx';
import ReportGenerating from '../Components/ReportGenerating.jsx';

const MentalHealthReport = () => {
  const { user } = useUser();
  const { reportId } = useParams();
  const navigate = useNavigate();
  const [reportData, setReportData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      if (!reportId) return;
      try {
        setLoading(true);
        const response = await fetch(`${import.meta.env.VITE_APP_URL ?? ''}/report/intake/${reportId}`, {
          headers: {
            Accept: 'application/json',
          },
        });

        if (response.status === 500) {
          setError({ status: 500 });
          setLoading(false);
          return;
        }

        if (!response.ok) {
          setError({ status: response.status });
          setLoading(false);
          return;
        }

        const data = await response.json();

        setReportData(data);
        setError(null);
      } catch (err) {
        setError({ status: 'error', message: err.message });
      } finally {
        setLoading(false);
      }
    };

    if (!reportId) {
      navigate('/auth/report');
      return;
    }
    fetchData();
  }, [reportId, navigate]);

  const isEmailVerified = user.emailAddresses.some((emailObj) => emailObj.verification.status === 'verified');
  if (!isEmailVerified) {
    return <EmailVerification />;
  }

  // Prevent any redirects if we have a 500 status
  if (error?.status === 500) {
    return <ReportGenerating />;
  }

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div>Error loading report</div>;
  }

  if (!reportData) {
    return <Loading />;
  }

  return (
    <div className="flex justify-center items-start min-h-screen bg-gray-50 px-4 py-8">
      <Report data={reportData} />
    </div>
  );
};

export default MentalHealthReport;
