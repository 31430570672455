import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OrganizationProfile, useUser } from '@clerk/clerk-react';
import { ArrowLeft } from 'lucide-react';
import { Button } from '../Components/ui/button.jsx';

const OrganizationManagement = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useUser();
  // Get the latest organization (first in the array)
  const latestOrganization = user?.organizationMemberships?.length > 0 ? user?.organizationMemberships[0] : null;

  const onOrganizationChange = (newOrganization) => {
    if (newOrganization.id && user?.unsafeMetadata?.organizationId !== newOrganization.id) {
      user?.update({
        unsafeMetadata: {
          ...user?.unsafeMetadata,
          organizationId: newOrganization.id,
        },
      });
    }
  };

  useEffect(() => {
    // Make sure we're using the latest organization
    if (latestOrganization?.organization?.id && user?.unsafeMetadata?.organizationId !== latestOrganization.organization.id) {
      onOrganizationChange(latestOrganization.organization);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latestOrganization?.organization?.id]);

  return (
    <div className="mx-auto md:px-4 py-6 sm:max-w-4xl md:max-w-7xl">
      <div className="mb-4 flex items-center justify-start">
        <Button variant="ghost" onClick={() => navigate('/auth/dashboard')} className="flex items-center space-x-2">
          <ArrowLeft className="w-5 h-5 hover:text-teal-400" />
        </Button>
        <h1 className="text-2xl font-bold ml-2">{t('organization.management')}</h1>
      </div>

      <div className="bg-white rounded-lg shadow-lg p-6 w-full overflow-x-auto">
        <OrganizationProfile
          appearance={{
            elements: {
              rootBox: 'w-full',
              card: 'w-full',
              cardHeader: 'w-full mb-4 border-b pb-2',
              cardContent: 'w-full',
            },
          }}
        />
      </div>
    </div>
  );
};

export default OrganizationManagement;
