import { Sparkles } from 'lucide-react';

const ReportGenerating = () => {
  return (
    <>
      <style>
        {`
          @keyframes backAndForth {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-20px);
            }
            100% {
              transform: translateY(0);
            }
          }
        `}
      </style>
      <div className="flex justify-center items-center h-[calc(100vh-100px)] bg-white">
        <div className="text-center">
          <Sparkles style={{ animation: 'backAndForth 2s ease-in-out infinite' }} className="h-16 w-16 text-[#1E4D36] mx-auto mb-4" />
          <h1 className="text-2xl font-semibold text-gray-900 mb-2">Report Generation in Progress</h1>
          <p className="text-gray-600">Your report is being generated. Please check back in 2 minutes.</p>
        </div>
      </div>
    </>
  );
};

export default ReportGenerating;
