import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDown, Globe } from 'lucide-react';
import { Button } from './ui/button.jsx';
import { RootContext } from '../rootContext.jsx';

const languages = [
  { code: 'en', name: 'English' },
  { code: 'it', name: 'Italiano' },
  { code: 'uk', name: 'Українська' },
];

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { language, setLanguage } = useContext(RootContext);

  const currentLanguage = languages.find((lang) => lang.code === language) || languages[0];

  const handleLanguageChange = (languageCode) => {
    i18n.changeLanguage(languageCode);
    setLanguage(languageCode);
    setIsOpen(false);
  };

  return (
    <div className="relative flex justify-end">
      <Button variant="ghost" className="flex items-center lg:gap-2 text-sm" onClick={() => setIsOpen(!isOpen)}>
        <Globe className="w-4 h-4 ml-2 text-teal-600" />
        <span className="hidden sm:block md:block">{currentLanguage.name}</span>
        <ChevronDown className={`h-4 w-4 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </Button>

      {isOpen && (
        <div className="absolute top-full right-0 mt-1 w-32 bg-white rounded-md shadow-lg py-1 z-50">
          {languages.map((lang) => (
            <button
              key={lang.code}
              onClick={() => handleLanguageChange(lang.code)}
              className={`w-full text-left lg:px-4 py-2 text-sm hover:bg-gray-100 ${
                lang.code === currentLanguage.code ? 'text-teal-600 font-medium' : 'text-gray-700'
              }`}
            >
              {lang.name}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSelector;
