import { useState, useEffect, useRef, useCallback } from 'react';
import { useUser } from '@clerk/clerk-react';
import { useAxios } from '../Components/axiosContext.js';

export const useSubscription = () => {
  const [subscription, setSubscription] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user, isLoaded: isUserLoaded } = useUser();
  const axios = useAxios();
  const lastCheckedRef = useRef(0);

  // Memoize the check subscription function to prevent unnecessary re-renders
  const checkSubscription = useCallback(async () => {
    // Throttle subscription checks (only check every 2 minutes)
    const now = Date.now();
    if (now - lastCheckedRef.current < 120000) return;
    lastCheckedRef.current = now;

    if (!isUserLoaded) {
      return;
    }

    if (!user || !axios) {
      setSubscription({ isValid: false });
      setError(new Error('User or API client not available'));
      setIsLoading(false);
      return;
    }

    setIsLoading(true);

    try {
      const res = await axios.get('/payments/check-subscription');
      if (res?.data?.isValid) {
        setSubscription({
          id: res.data.subscription.id,
          status: res.data.subscription.status,
          current_period_end: res.data.subscription.current_period_end,
          cancel_at: res.data.subscription.cancel_at,
          canceled_at: res.data.subscription.canceled_at,
          isValid: true,
          items: {
            data: [
              {
                price: {
                  id: res.data.subscription.plan.id,
                  amount: res.data.subscription.plan.amount,
                  interval: res.data.subscription.plan.interval,
                },
              },
            ],
          },
        });
      } else {
        setSubscription({ isValid: false, message: res.data.message });
      }
      setError(null);
    } catch (err) {
      setError(err);
      setSubscription({ isValid: false, message: err.message });
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoaded, user]);

  useEffect(() => {
    let isMounted = true;

    const runCheck = async () => {
      if (!isMounted) return;
      await checkSubscription();
    };

    runCheck();

    return () => {
      isMounted = false;
    };
  }, [checkSubscription]);

  // Don't derive isValid until we have finished loading
  const isValid = !isLoading && subscription?.isValid === true;

  return {
    subscription,
    isLoading,
    error,
    isValid,
    isInitialized: !isLoading && subscription !== null,
  };
};
