/* eslint-disable no-console */
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { AxiosContext } from './axiosContext.js';

const UnauthenticatedAxiosProvider = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const axiosRef = useRef(null);

  useEffect(() => {
    const initializeAxios = async () => {
      try {
        const baseURL = import.meta.env.VITE_NODE_API_URL;
        if (!baseURL) {
          throw new Error('API URL configuration is missing');
        }
        // Ensure baseURL ends with a slash
        const normalizedBaseURL = baseURL.endsWith('/') ? baseURL : `${baseURL}/`;

        axiosRef.current = axios.create({
          baseURL: normalizedBaseURL,
          headers: {
            'Content-Type': 'application/json',
          },
        });

        // Set up error interceptor
        axiosRef.current.interceptors.response.use(
          (response) => response,
          async (error) => {
            if (error.response?.status === 401) {
              console.error('Unauthorized request:', error.config.url);
            }
            return Promise.reject(error);
          },
        );

        setIsReady(true);
      } catch (error) {
        console.error('Failed to initialize axios:', error);
        setIsReady(true);
      }
    };

    initializeAxios();
  }, []);

  if (!isReady) {
    return <div>Loading...</div>;
  }

  return <AxiosContext.Provider value={axiosRef.current}>{children}</AxiosContext.Provider>;
};

UnauthenticatedAxiosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UnauthenticatedAxiosProvider };
