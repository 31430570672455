import { useState, useEffect, useContext } from 'react';
import { DayPicker } from 'react-day-picker';
import { enUS, uk, it } from 'date-fns/locale';
import PropTypes from 'prop-types';
import { isBefore, isSameDay } from 'date-fns';
import { cn } from '../lib/utils.js';
import 'react-day-picker/dist/style.css';
import TimezoneSelector from './TimezoneComponent.jsx';
import { RootContext } from '../rootContext.jsx';

const CalendarView = ({
  className,
  classNames,
  showOutsideDays = true,
  existingCalls = [],
  selectedTimezone,
  refreshTrigger,
  refreshKey,
  onSelect,
  ...props
}) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const { language } = useContext(RootContext);

  const modifiers = {
    past_call: (date) => {
      if (isBefore(date, today)) return false;
      const callOnDate = existingCalls.find((call) => {
        return isSameDay(date, call.scheduledAt) && ['COMPLETED'].includes(call.status);
      });
      return !!callOnDate;
    },
    future_call: (date) => {
      if (isBefore(date, today)) return false;
      const callOnDate = existingCalls.find((call) => {
        return isSameDay(date, call.scheduledAt) && (call.status === 'PENDING' || call.status === 'SCHEDULED');
      });
      return !!callOnDate;
    },
    missed_call: (date) => {
      if (isBefore(date, today)) return false;
      const callOnDate = existingCalls.find((call) => {
        return isSameDay(date, call.scheduledAt) && call.status === 'MISSED';
      });
      return !!callOnDate;
    },
    disabled: (date) => isBefore(date, today), // disable past dates
  };

  const modifiersClassNames = {
    past_call: 'bg-slate-500 text-white hover:bg-white-600 hover:text-slate-900',
    future_call: 'bg-black text-white hover:bg-white-600 hover:text-slate-900',
    missed_call: 'bg-red-500 text-white hover:bg-white-600 hover:text-slate-900',
  };

  // Define an onDayClick handler that always calls the parent's onSelect callback.
  const handleDayClick = (day) => {
    if (onSelect) {
      onSelect(day);
    }
  };

  const [locale, setLocale] = useState(enUS);
  useEffect(() => {
    // update locale
    setLocale(() => {
      if (language === 'uk') return uk;
      if (language === 'it') return it;
      return enUS;
    });
  }, [language]);

  return (
    <div className={`rounded-lg border md:p-4 sm:p-2 w-full max-w-xl mx-auto overflow-x-auto ${className || ''}`}>
      <DayPicker
        showOutsideDays={showOutsideDays}
        className={cn('w-full p-1 sm:p-3', className)}
        timeZone={selectedTimezone?.value}
        locale={locale}
        modifiers={modifiers}
        modifiersClassNames={modifiersClassNames}
        onDayClick={handleDayClick}
        classNames={{
          month: 'w-full text-base sm:text-lg p-0',
          day: 'h-14 w-14 sm:h-16 sm:w-16 text-center p-3 text-base sm:text-xl rounded-full hover:bg-gray-100 text-gray-900',
          selected:
            'bg-slate-100 h-14 w-14 sm:h-16 sm:w-16 text-center text-black hover:bg-slate-200 hover:text-black focus:bg-slate-400 focus:text-black focus:border-slate-900',
          today:
            'bg-green-900 text-white rounded-full w-14 h-14 sm:w-16 sm:h-16 flex items-center justify-center text-center hover:bg-green-800 focus:bg-green-600',
          active:
            'bg-slate-300 text-gray-900 hover:bg-slate-200 hover:text-slate-900 focus:bg-slate-200 focus:text-slate-900 border border-slate-200 rounded-full w-14 h-14 sm:w-16 sm:h-16 flex items-center justify-center text-center',
          head_cell: 'text-base sm:text-lg text-gray-900 font-normal p-4',
          row: 'flex justify-between w-full gap-1 sm:gap-4',
          cell: 'p-0 relative text-center flex items-center justify-center',
          nav_button: 'h-10 w-10 p-0 opacity-75 hover:opacity-100 rounded-full',
        }}
        {...props}
      />
      <div className="mt-6 space-y-4">
        <TimezoneSelector refreshTrigger={refreshTrigger} />
      </div>
    </div>
  );
};

CalendarView.propTypes = {
  className: PropTypes.string,
  classNames: PropTypes.object,
  showOutsideDays: PropTypes.bool,
  selectedTimezone: PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    offset: PropTypes.string,
  }),
  existingCalls: PropTypes.arrayOf(
    PropTypes.shape({
      scheduledAt: PropTypes.string.isRequired,
      dateInTimezone: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
    }),
  ),
  onTimezoneChange: PropTypes.func,
  refreshTrigger: PropTypes.func,
  refreshKey: PropTypes.number,
  onSelect: PropTypes.func,
};

export default CalendarView;
