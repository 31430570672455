import { useUser } from '@clerk/clerk-react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { isValid, format } from 'date-fns';
import { Calendar, ChevronDown, ChevronUp } from 'react-feather';
import MentalHealthDashboard from '../Components/followUpComponent.jsx';
import Pagination from '../Components/pagination.jsx';
import { useAxios } from '../Components/axiosContext.js';
import { FollowupSkeleton } from '../Components/skeleton.jsx';

const Section = ({ onToggle, isExpanded = false, title, icon, children }) => {
  return (
    <div className="mb-4 border border-gray-300 rounded-md shadow-sm hover:shadow-lg transition-shadow duration-300 overflow-hidden">
      <div className="bg-white p-3 flex justify-between items-center cursor-pointer hover:bg-gray-100 transition-colors duration-200" onClick={onToggle}>
        <h2 className="text-md font-medium flex items-center text-gray-800">
          {icon && <span className="text-gray-600 mr-2">{icon}</span>}
          {title}
        </h2>
        <div className="text-gray-500">
          {isExpanded ? (
            <ChevronUp size={16} className="hover:text-gray-800 transition-colors" />
          ) : (
            <ChevronDown size={16} className="hover:text-gray-800 transition-colors" />
          )}
        </div>
      </div>
      {isExpanded && <div className="p-3 bg-gray-50 text-sm text-gray-700">{children}</div>}
    </div>
  );
};

Section.propTypes = {
  onToggle: PropTypes.func.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  children: PropTypes.node.isRequired,
};

const Followup = () => {
  const { user } = useUser();
  const axios = useAxios();
  const [reportIds, setReportIds] = useState([]);
  const [total, setTotal] = useState(0);
  const [expandedSections, setExpandedSections] = useState({});
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);

  // Fetch data when user ID or currentPage changes
  useEffect(() => {
    if (user.id) {
      const getFollowupIds = async () => {
        const offset = (currentPage - 1) * itemsPerPage;
        setLoading(true);
        try {
          const response = await axios.get('calls/reports', {
            params: {
              limit: itemsPerPage,
              offset,
              reportType: 'FOLLOWUP',
              order_by: 'createdAt',
              order: 'desc',
            },
          });
          if (response?.data?.data) {
            setReportIds(response.data.data);
          }
          setTotal(response.data.total);
          setLoading(false);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching follow-ups:', error.response?.data || error.message);
          setLoading(false);
          setReportIds([]);
        }
      };
      getFollowupIds();
    }
  }, [axios, user.id, currentPage]); // Dependency array includes currentPage

  const toggleSection = (sectionId) => {
    setExpandedSections((prevSections) => {
      const updatedSections = { ...prevSections, [sectionId]: !prevSections[sectionId] };
      return updatedSections;
    });
  };

  return (
    <div className="max-w-4xl mx-auto p-6 mt-10 bg-white shadow-lg rounded-lg" style={{ position: 'relative', minHeight: '85vh' }}>
      <h1 className="text-3xl font-bold mb-6 text-[#2B513B]">Your Weekly Mental Health Dashboard</h1>
      <div className="flex justify-between items-center mb-8">
        <div>
          <p className="text-lg font-medium text-gray-700">{user.fullName}</p>
          <p className="text-sm text-gray-500">{user.email}</p>
        </div>
      </div>
      {loading ? (
        <FollowupSkeleton />
      ) : (
        <>
          {reportIds.length ? (
            <>
              {reportIds.map((report) => (
                <div style={{ paddingBottom: '10px' }} key={report?.id}>
                  <Section
                    key={report?.id}
                    title={isValid(new Date(report?.createdAt)) ? format(new Date(report?.createdAt), 'yyyy-MM-dd HH:mm:ss') : 'Unknown Date'}
                    icon={<Calendar className="text-blue-500" />}
                    onToggle={() => toggleSection(report?.id)}
                    isExpanded={!!expandedSections[report?.id] || false}
                  >
                    <MentalHealthDashboard reportId={report?.id} />
                  </Section>
                </div>
              ))}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bottom: 10,
                  zIndex: 1000,
                }}
              >
                <Pagination totalItems={total} itemsPerPage={itemsPerPage} currentPage={currentPage} onPageChange={(page) => setCurrentPage(page)} />
              </div>
            </>
          ) : (
            <div className="text-center mt-10">
              <p className="text-gray-500">No record found.</p>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Followup;
