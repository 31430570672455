/* eslint-disable no-console */
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useAuth } from '@clerk/clerk-react';
import { AxiosContext } from './axiosContext.js';

const AxiosProvider = ({ children }) => {
  const { getToken, isLoaded, isSignedIn } = useAuth();
  const [isReady, setIsReady] = useState(false);
  const axiosRef = useRef(null);

  useEffect(() => {
    const initializeAxios = async () => {
      if (!isLoaded || !isSignedIn) {
        setIsReady(true);
        return;
      }

      try {
        const token = await getToken();
        const baseURL = import.meta.env.VITE_NODE_API_URL;
        if (!baseURL) {
          throw new Error('API URL configuration is missing');
        }
        // Ensure baseURL ends with a slash
        const normalizedBaseURL = baseURL.endsWith('/') ? baseURL : `${baseURL}/`;

        axiosRef.current = axios.create({
          baseURL: normalizedBaseURL,
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
        });

        // Set up interceptors
        axiosRef.current.interceptors.request.use(
          async (config) => {
            try {
              // Refresh token on each request
              const freshToken = await getToken();
              return {
                ...config,
                headers: {
                  ...config.headers,
                  Authorization: `Bearer ${freshToken}`,
                },
              };
            } catch (error) {
              console.error('Error in request interceptor:', error);
              return Promise.reject(error);
            }
          },
          (error) => {
            console.error('Request interceptor error:', error);
            return Promise.reject(error);
          },
        );

        axiosRef.current.interceptors.response.use(
          (response) => response,
          async (error) => {
            if (error.response?.status === 401) {
              console.error('Unauthorized request:', error.config.url);
            }
            return Promise.reject(error);
          },
        );

        setIsReady(true);
      } catch (error) {
        console.error('Failed to initialize axios:', error);
        setIsReady(true);
      }
    };

    initializeAxios();
  }, [getToken, isLoaded, isSignedIn]);

  if (!isReady) {
    return <div>Loading...</div>;
  }

  return <AxiosContext.Provider value={axiosRef.current}>{children}</AxiosContext.Provider>;
};

AxiosProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AxiosProvider };
