import { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import TimezoneSelect, { allTimezones } from 'react-timezone-select';
import { Loader, Pencil, Plus } from 'lucide-react';
import { toZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Toast from './Toast.jsx';
import i18n from '../i18n.js';

const localizedTimezones = (language) => {
  // Create a custom mapping based on your current language
  if (language === 'it') {
    return {
      ...allTimezones,
      'Pacific/Midway': 'Isola di Midway, Samoa',
      'Pacific/Honolulu': 'Hawaii',
      'America/Juneau': 'Alaska',
      'America/Boise': 'Ora delle Montagne',
      'America/Dawson': 'Dawson, Yukon',
      'America/Chihuahua': 'Chihuahua, La Paz, Mazatlán',
      'America/Phoenix': 'Arizona',
      'America/Chicago': 'Ora Centrale',
      'America/Regina': 'Saskatchewan',
      'America/Mexico_City': 'Guadalajara, Città del Messico, Monterrey',
      'America/Belize': 'America Centrale',
      'America/Detroit': 'Ora Orientale',
      'America/Bogota': 'Bogotá, Lima, Quito',
      'America/Caracas': 'Caracas, La Paz',
      'America/Santiago': 'Santiago',
      'America/St_Johns': 'Terranova e Labrador',
      'America/Sao_Paulo': 'Brasilia',
      'America/Tijuana': 'Tijuana',
      'America/Montevideo': 'Montevideo',
      'America/Argentina/Buenos_Aires': 'Buenos Aires, Georgetown',
      'America/Godthab': 'Groenlandia',
      'America/Los_Angeles': 'Ora del Pacifico',
      'Atlantic/Azores': 'Azzorre',
      'Atlantic/Cape_Verde': 'Capo Verde',
      GMT: 'UTC',
      'Europe/London': 'Edimburgo, Londra',
      'Europe/Dublin': 'Dublino',
      'Europe/Lisbon': 'Lisbona',
      'Africa/Casablanca': 'Casablanca, Monrovia',
      'Atlantic/Canary': 'Isole Canarie',
      'Europe/Belgrade': 'Belgrado, Bratislava, Budapest, Lubiana, Praga',
      'Europe/Sarajevo': 'Sarajevo, Skopje, Varsavia, Zagabria',
      'Europe/Brussels': 'Bruxelles, Copenaghen, Madrid, Parigi',
      'Europe/Amsterdam': 'Amsterdam, Berlino, Berna, Roma, Stoccolma, Vienna',
      'Africa/Algiers': 'Africa Centro-Occidentale',
      'Europe/Bucharest': 'Bucarest',
      'Africa/Cairo': 'Il Cairo',
      'Europe/Helsinki': 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
      'Europe/Athens': 'Atene',
      'Asia/Jerusalem': 'Gerusalemme',
      'Africa/Harare': 'Harare, Pretoria',
      'Europe/Moscow': 'Istanbul, Minsk, Mosca, San Pietroburgo, Volgograd',
      'Asia/Kuwait': 'Kuwait, Riad',
      'Africa/Nairobi': 'Nairobi',
      'Asia/Baghdad': 'Baghdad',
      'Asia/Tehran': 'Teheran',
      'Asia/Dubai': 'Abu Dhabi, Mascate',
      'Asia/Baku': 'Baku, Tbilisi, Yerevan',
      'Asia/Kabul': 'Kabul',
      'Asia/Yekaterinburg': 'Ekaterinburg',
      'Asia/Karachi': 'Islamabad, Karachi, Tashkent',
      'Asia/Kolkata': 'Chennai, Kolkata, Mumbai, Nuova Delhi',
      'Asia/Kathmandu': 'Kathmandu',
      'Asia/Dhaka': 'Astana, Dhaka',
      'Asia/Colombo': 'Sri Jayawardenepura',
      'Asia/Almaty': 'Almaty, Novosibirsk',
      'Asia/Rangoon': 'Yangon, Rangoon',
      'Asia/Bangkok': 'Bangkok, Hanoi, Jakarta',
      'Asia/Krasnoyarsk': 'Krasnoyarsk',
      'Asia/Shanghai': 'Pechino, Chongqing, Hong Kong SAR, Urumqi',
      'Asia/Kuala_Lumpur': 'Kuala Lumpur, Singapore',
      'Asia/Taipei': 'Taipei',
      'Australia/Perth': 'Perth',
      'Asia/Irkutsk': 'Irkutsk, Ulaanbaatar',
      'Asia/Seoul': 'Seoul',
      'Asia/Tokyo': 'Osaka, Sapporo, Tokyo',
      'Asia/Yakutsk': 'Yakutsk',
      'Australia/Darwin': 'Darwin',
      'Australia/Adelaide': 'Adelaide',
      'Australia/Sydney': 'Canberra, Melbourne, Sydney',
      'Australia/Brisbane': 'Brisbane',
      'Australia/Hobart': 'Hobart',
      'Asia/Vladivostok': 'Vladivostok',
      'Pacific/Guam': 'Guam, Port Moresby',
      'Asia/Magadan': 'Magadan, Isole Salomone, Nuova Caledonia',
      'Asia/Kamchatka': 'Kamchatka, Isole Marshall',
      'Pacific/Fiji': 'Isole Figi',
      'Pacific/Auckland': 'Auckland, Wellington',
      'Pacific/Tongatapu': "Nuku'alofa",
    };
  }

  if (language === 'uk') {
    return {
      ...allTimezones,
      'Pacific/Midway': 'Острів Мідвей, Самоа',
      'Pacific/Honolulu': 'Гаваї',
      'America/Juneau': 'Аляска',
      'America/Boise': 'Гірський час',
      'America/Dawson': 'Доусон, Юкон',
      'America/Chihuahua': 'Чихуахуа, Ла-Пас, Мазатлан',
      'America/Phoenix': 'Аризона',
      'America/Chicago': 'Центральний час',
      'America/Regina': 'Саскачеван',
      'America/Mexico_City': 'Гвадалахара, Мехіко, Монтеррей',
      'America/Belize': 'Центральна Америка',
      'America/Detroit': 'Східний час',
      'America/Bogota': 'Богота, Ліма, Кіто',
      'America/Caracas': 'Каракас, Ла-Пас',
      'America/Santiago': 'Сантьяго',
      'America/St_Johns': 'Ньюфаундленд і Лабрадор',
      'America/Sao_Paulo': 'Бразиліа',
      'America/Tijuana': 'Тіхуана',
      'America/Montevideo': 'Монтевідео',
      'America/Argentina/Buenos_Aires': 'Буенос-Айрес, Джорджтаун',
      'America/Godthab': 'Гренландія',
      'America/Los_Angeles': 'Тихоокеанський час',
      'Atlantic/Azores': 'Азорські острови',
      'Atlantic/Cape_Verde': 'Кабо-Верде',
      GMT: 'UTC',
      'Europe/London': 'Едінбург, Лондон',
      'Europe/Dublin': 'Дублін',
      'Europe/Lisbon': 'Лісабон',
      'Africa/Casablanca': 'Касабланка, Монровія',
      'Atlantic/Canary': 'Канарські острови',
      'Europe/Belgrade': 'Белград, Братислава, Будапешт, Любляна, Прага',
      'Europe/Sarajevo': "Сараєво, Скоп'є, Варшава, Загреб",
      'Europe/Brussels': 'Брюссель, Копенгаген, Мадрид, Париж',
      'Europe/Amsterdam': 'Амстердам, Берлін, Берн, Рим, Стокгольм, Відень',
      'Africa/Algiers': 'Західно-Центральна Африка',
      'Europe/Bucharest': 'Бухарест',
      'Africa/Cairo': 'Каїр',
      'Europe/Helsinki': 'Гельсінкі, Київ, Рига, Софія, Таллінн, Вільнюс',
      'Europe/Athens': 'Афіни',
      'Asia/Jerusalem': 'Єрусалим',
      'Africa/Harare': 'Хараре, Преторія',
      'Europe/Moscow': 'Стамбул, Мінськ, Москва, Санкт-Петербург, Волгоград',
      'Asia/Kuwait': 'Ель-Кувейт, Ер-Ріяд',
      'Africa/Nairobi': 'Найробі',
      'Asia/Baghdad': 'Багдад',
      'Asia/Tehran': 'Тегеран',
      'Asia/Dubai': 'Абу-Дабі, Маскат',
      'Asia/Baku': 'Баку, Тбілісі, Єреван',
      'Asia/Kabul': 'Кабул',
      'Asia/Yekaterinburg': 'Єкатеринбург',
      'Asia/Karachi': 'Ісламабад, Карачі, Ташкент',
      'Asia/Kolkata': 'Ченнаї, Колката, Мумбаї, Нью-Делі',
      'Asia/Kathmandu': 'Катманду',
      'Asia/Dhaka': 'Астана, Дакка',
      'Asia/Colombo': 'Шрі-Джаяварденепура',
      'Asia/Almaty': 'Алмати, Новосибірськ',
      'Asia/Rangoon': 'Янгон, Рангун',
      'Asia/Bangkok': 'Бангкок, Ханой, Джакарта',
      'Asia/Krasnoyarsk': 'Красноярськ',
      'Asia/Shanghai': 'Пекін, Чунцін, Гонконг, Урумчі',
      'Asia/Kuala_Lumpur': 'Куала-Лумпур, Сінгапур',
      'Asia/Taipei': 'Тайбей',
      'Australia/Perth': 'Перт',
      'Asia/Irkutsk': 'Іркутськ, Улан-Батор',
      'Asia/Seoul': 'Сеул',
      'Asia/Tokyo': 'Осака, Саппоро, Токіо',
      'Asia/Yakutsk': 'Якутськ',
      'Australia/Darwin': 'Дарвін',
      'Australia/Adelaide': 'Аделаїда',
      'Australia/Sydney': 'Канберра, Мельбурн, Сідней',
      'Australia/Brisbane': 'Брісбен',
      'Australia/Hobart': 'Хобарт',
      'Asia/Vladivostok': 'Владивосток',
      'Pacific/Guam': 'Гуам, Порт-Морсбі',
      'Asia/Magadan': 'Магадан, Соломонові острови, Нова Каледонія',
      'Asia/Kamchatka': 'Камчатка, Маршаллові острови',
      'Pacific/Fiji': 'Фіджі',
      'Pacific/Auckland': 'Окленд, Веллінгтон',
      'Pacific/Tongatapu': 'Нукуалофа',
    };
  }
  // Fallback to default labels for other languages
  return allTimezones;
};

const TimezoneComponent = ({ refreshTrigger }) => {
  const { user } = useUser();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTimezone, setSelectedTimezone] = useState(null);
  const [showToast, setShowToast] = useState(false);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [isEditing, setIsEditing] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    message: '',
    variant: 'success',
  });

  useEffect(() => {
    const fetchUserTimezone = async () => {
      try {
        const timeZone = user?.unsafeMetadata?.preferences?.timeZone;
        if (timeZone) {
          setSelectedTimezone({ value: timeZone, label: timeZone });
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Failed to fetch timezone:', err);
      } finally {
        setIsLoading(false);
      }
    };
    fetchUserTimezone();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  const handleTimezoneChange = async (newTimezone) => {
    try {
      if (!newTimezone?.value) {
        return;
      }
      setIsLoading(true);
      await user.update({
        unsafeMetadata: {
          ...user?.unsafeMetadata,
          preferences: {
            ...user?.unsafeMetadata?.preferences,
            timeZone: newTimezone?.value,
          },
        },
      });
      setSelectedTimezone(newTimezone);
      setToastMessage({
        message: 'Timezone updated successfully',
        variant: 'success',
      });
      setShowToast(true);
      setIsEditing(false);
      refreshTrigger();
    } catch (error) {
      setToastMessage({
        message: 'Failed to update timezone',
        variant: 'error',
      });
      setShowToast(true);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">
        <span className="text-gray-500 flex items-center">
          <span>Loading...</span>
          <Loader className="animate-spin w-4 h-4 text-gray-500 ml-2" />
        </span>
      </div>
    );
  }

  return (
    <>
      {selectedTimezone?.value && <span className="text-sm font-bold text-gray-600 py-6 my-4">{t('timezoneSelector.defaultTimezone')}</span>}
      <div className="flex flex-col items-start min-w-[300px] p-4 rounded-lg border border-gray-200">
        <div className="w-full">
          {isEditing ? (
            <div className="max-w-md w-full">
              <TimezoneSelect
                label={t('timezoneSelector.label')}
                value={selectedTimezone}
                onChange={handleTimezoneChange}
                timezones={localizedTimezones(i18n.language)}
                className="w-full"
                styles={{
                  control: (base) => ({
                    ...base,
                    borderColor: '#FFFFFF',
                    borderRadius: '0px',
                    borderWidth: '0px',
                    boxShadow: 'none',
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999,
                    position: 'absolute',
                  }),
                  menuPortal: (base) => ({
                    ...base,
                    zIndex: 9999,
                  }),
                  option: (base, state) => ({
                    ...base,
                    backgroundColor: state.isSelected ? '#e0f2f1' : base.backgroundColor,
                    color: state.isSelected ? '#00695c' : base.color,
                    '&:hover': {
                      backgroundColor: '#e0f2f1',
                    },
                  }),
                }}
                menuPortalTarget={document.body}
                menuPosition="fixed"
              />
            </div>
          ) : (
            <div className="flex justify-between items-center w-full">
              {selectedTimezone?.value ? (
                <div className="flex flex-col">
                  <div className="flex items-center space-x-2">
                    <span className="font-medium text-gray-900">{selectedTimezone.label}</span>
                    <button onClick={() => setIsEditing(true)} className="p-1 hover:bg-gray-100 rounded-full transition-colors">
                      <Pencil className="w-4 h-4 text-gray-500" />
                    </button>
                  </div>
                  <div className="mt-1">
                    <span className="text-gray-500">Current Time: </span>
                    <span className="text-gray-700 font-medium">{format(toZonedTime(currentTime, selectedTimezone.value), 'h:mma')}</span>
                  </div>
                </div>
              ) : (
                <button onClick={() => setIsEditing(true)} className="flex items-center space-x-2 p-1 hover:bg-gray-100 rounded-full transition-colors">
                  <Plus className="w-4 h-4 text-gray-500" />
                  <span className="text-gray-700 font-medium">Select Time Zone</span>
                </button>
              )}
            </div>
          )}
        </div>
        {showToast && <Toast message={toastMessage.message} variant={toastMessage.variant} setShowToast={setShowToast} />}
      </div>
    </>
  );
};

TimezoneComponent.propTypes = {
  refreshTrigger: PropTypes.func.isRequired,
};

export default TimezoneComponent;
