// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Heart, BookOpen, Moon, Star, ArrowRight, Trophy, Calendar } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './card.tsx';
import { WeeklyMentalHealthReportSkeleton } from './skeleton.jsx';

const MentalHealthDashboard = ({ reportId }) => {
  // const { journalTemplate: reportData } = journalData;
  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('reflection');
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(`${import.meta.env.VITE_APP_URL ?? ''}/report/followup/${reportId}`, {
        headers: { Accept: 'application/json' },
      });
      setReportData(await response.json());

      setLoading(false);
    };
    if (reportId) {
      setLoading(true);
      fetchData();
    }
  }, [reportId]);

  if (loading) {
    return <WeeklyMentalHealthReportSkeleton />;
  }

  const TabButton = ({ id, icon: Icon, label }) => {
    TabButton.propTypes = {
      id: PropTypes.string.isRequired,
      icon: PropTypes.elementType.isRequired,
      label: PropTypes.string.isRequired,
    };
    return (
      <button
        onClick={() => setActiveTab(id)}
        className={`flex items-center space-x-2 px-4 py-2 rounded-lg transition-all ${
          activeTab === id ? 'bg-blue-50 text-blue-700 font-medium shadow-sm' : 'text-gray-600 hover:bg-gray-50'
        }`}
      >
        <Icon className="w-4 h-4" />
        <span>{label}</span>
      </button>
    );
  };

  return (
    <div className="max-w-4xl mx-auto p-6 space-y-6 bg-gray-50">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold text-gray-800">Mental Health Journal</h1>
          <p className="text-gray-500 mt-1">{reportData?.journalTemplate?.metadata?.date}</p>
        </div>
        <button className="p-2 hover:bg-gray-100 rounded-full">
          <Calendar className="w-5 h-5" />
        </button>
      </div>

      {/* Navigation */}
      <div className="flex space-x-4 overflow-x-auto pb-2">
        <TabButton id="reflection" icon={Heart} label="Daily Reflection" />
        <TabButton id="mood" icon={Star} label="Mood & Energy" />
        <TabButton id="sleep" icon={Moon} label="Sleep" />
        <TabButton id="wins" icon={Trophy} label="Achievements" />
        <TabButton id="forward" icon={ArrowRight} label="Goals" />
      </div>

      {/* Content */}
      <div className="space-y-6">
        {/* Reflection Tab */}
        {activeTab === 'reflection' && (
          <Card className="bg-white border-none shadow-sm">
            <CardHeader>
              <div className="flex items-center space-x-2">
                <BookOpen className="w-5 h-5 text-blue-600" />
                <CardTitle>{reportData?.journalTemplate?.dailyReflection?.title}</CardTitle>
              </div>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <p className="text-gray-600">{reportData?.journalTemplate?.dailyReflection?.content}</p>
                <div className="bg-blue-50 p-4 rounded-lg">
                  <p className="text-blue-800 font-medium">Highlight</p>
                  <p className="text-blue-600 mt-1">{reportData?.journalTemplate?.dailyReflection?.highlight}</p>
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        {/* Mood Tab */}
        {activeTab === 'mood' && (
          <Card className="bg-white border-none shadow-sm">
            <CardHeader>
              <CardTitle>{reportData?.journalTemplate?.moodTracking?.title}</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="bg-gradient-to-br from-yellow-50 to-orange-50 p-4 rounded-xl">
                  <div className="text-2xl mb-2">😊</div>
                  <h3 className="font-semibold text-gray-800">Mood: {reportData?.journalTemplate?.moodTracking?.metrics?.mood?.score}/10</h3>
                  <div className="mt-2 text-sm text-gray-600">
                    Weekend: {reportData?.journalTemplate?.moodTracking?.metrics?.mood?.breakdown?.weekend}/10
                    <br />
                    Weekday: {reportData?.journalTemplate?.moodTracking?.metrics?.mood?.breakdown?.weekday}/10
                  </div>
                  <p className="mt-2 text-sm text-gray-600">{reportData?.journalTemplate?.moodTracking?.metrics?.mood?.description}</p>
                </div>

                <div className="bg-gradient-to-br from-blue-50 to-cyan-50 p-4 rounded-xl">
                  <div className="text-2xl mb-2">⚡</div>
                  <h3 className="font-semibold text-gray-800">Energy: {reportData?.journalTemplate?.moodTracking?.metrics?.energy?.status}</h3>
                  <p className="mt-2 text-sm text-gray-600">{reportData?.journalTemplate?.moodTracking?.metrics?.energy?.description}</p>
                </div>

                <div className="bg-gradient-to-br from-purple-50 to-pink-50 p-4 rounded-xl">
                  <div className="text-2xl mb-2">😓</div>
                  <h3 className="font-semibold text-gray-800">Stress: {reportData?.journalTemplate?.moodTracking?.metrics?.stress?.status}</h3>
                  <p className="mt-2 text-sm text-gray-600">{reportData?.journalTemplate?.moodTracking?.metrics?.stress?.description}</p>
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        {/* Sleep Tab */}
        {activeTab === 'sleep' && (
          <Card className="bg-white border-none shadow-sm">
            <CardHeader>
              <CardTitle>{reportData?.journalTemplate?.sleepInsights?.title} 😴</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                <div className="bg-indigo-50 p-4 rounded-lg">
                  <h4 className="font-medium text-indigo-900">Sleep Schedule</h4>
                  <p className="mt-2 text-indigo-700">Bedtime: {reportData?.journalTemplate?.sleepInsights?.schedule?.typicalBedtime}</p>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <h4 className="font-medium text-gray-900">Quality</h4>
                    <p className="mt-1 text-gray-600">{reportData?.journalTemplate?.sleepInsights?.schedule?.description}</p>
                  </div>
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <h4 className="font-medium text-gray-900">Main Factor</h4>
                    <p className="mt-1 text-gray-600">{reportData?.journalTemplate?.sleepInsights?.schedule?.mainFactor}</p>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
        )}

        {/* Achievements Tab */}
        {activeTab === 'wins' && (
          <Card className="bg-white border-none shadow-sm">
            <CardHeader>
              <CardTitle>{reportData?.journalTemplate?.achievements?.title}</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="space-y-4">
                {reportData?.journalTemplate?.achievements?.weeklyWins.map((win, index) => (
                  <div key={index} className="flex items-center space-x-3 p-3 bg-green-50 rounded-lg">
                    <div className="flex-shrink-0">
                      <Trophy className="w-5 h-5 text-green-600" />
                    </div>
                    <p className="text-green-700">{win}</p>
                  </div>
                ))}
              </div>
            </CardContent>
          </Card>
        )}

        {/* Goals Tab */}
        {activeTab === 'forward' && (
          <Card className="bg-white border-none shadow-sm">
            <CardHeader>
              <CardTitle>{reportData?.journalTemplate?.goalSetting?.title}</CardTitle>
            </CardHeader>
            <CardContent className="space-y-6">
              <div className="bg-gradient-to-r from-purple-50 to-pink-50 p-4 rounded-lg">
                <h4 className="font-semibold text-gray-800 mb-3">Goals for Next Week</h4>
                <ol className="space-y-2">
                  {reportData?.journalTemplate?.goalSetting?.goals.map((goal, index) => (
                    <li key={index} className="flex items-center space-x-2">
                      <span className="flex-shrink-0 w-6 h-6 flex items-center justify-center bg-white rounded-full text-sm font-medium text-purple-600">
                        {index + 1}
                      </span>
                      <div>
                        <span className="text-gray-700">{goal?.goal}</span>
                        <p className="text-sm text-gray-600 mt-1">{goal?.description}</p>
                      </div>
                    </li>
                  ))}
                </ol>
              </div>

              <div className="bg-blue-50 p-4 rounded-lg">
                <h4 className="font-semibold text-gray-800 mb-2">Note to Self:</h4>
                <p className="text-gray-600 italic">{reportData?.journalTemplate?.goalSetting?.noteToSelf}</p>
              </div>
            </CardContent>
          </Card>
        )}
      </div>
    </div>
  );
};
MentalHealthDashboard.propTypes = {
  reportId: PropTypes.string.isRequired,
};

export default MentalHealthDashboard;
