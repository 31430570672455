import propTypes from 'prop-types';
import { createContext, useState, useEffect } from 'react';
import i18n from './i18n.js';

export const RootContext = createContext();

export const RootContextProvider = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [language, setLanguage] = useState(() => {
    // Try to get saved language from localStorage, fallback to browser language or 'en'
    const savedLang = localStorage.getItem('preferredLanguage');
    const browserLang = navigator.language.split('-')[0];
    const defaultLang = ['en', 'it', 'uk'].includes(browserLang) ? browserLang : 'en';
    return savedLang || defaultLang;
  });

  // Initialize i18n with saved language
  useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem('preferredLanguage', language);
  }, [language]);

  return (
    <RootContext.Provider
      value={{
        isSidebarOpen,
        setIsSidebarOpen,
        language,
        setLanguage,
      }}
    >
      {children}
    </RootContext.Provider>
  );
};

RootContextProvider.propTypes = {
  children: propTypes.node.isRequired,
};
