import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useUser } from '@clerk/clerk-react';
import { Button } from './ui/button.jsx';
import { Checkbox } from './ui/checkbox.jsx';

import { useReportId } from '../hooks/useReportId.js';

const RedirectToCall = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useUser();
  const { t } = useTranslation();
  const { reportId, isLoading } = useReportId();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      navigate('/auth/call');
    }, 1000);
  };
  useEffect(() => {
    if (user?.id && reportId && !isLoading) {
      navigate(`/auth/report/${reportId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, reportId, isLoading]);

  return (
    <div className="max-w-2xl mx-auto p-6 space-y-8 pt-24">
      <div className="text-center space-y-4">
        <h1 className="text-3xl font-bold text-gray-900">{t('redirectToCall.title')}</h1>
      </div>

      <div className="bg-white shadow-lg rounded-xl p-6 border border-gray-100">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">{t('redirectToCall.generalInformation')}</h2>
        <ul className="list-disc pl-5 space-y-3">
          <li className="text-gray-700">{t('redirectToCall.info.intake')}</li>
          <li className="text-gray-700">{t('redirectToCall.info.report')}</li>
        </ul>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="space-y-6">
          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Checkbox id="privacy" required />
              <label htmlFor="privacy" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                {t('redirectToCall.privacyPolicy')} <span className="text-red-600">*</span>
              </label>
            </div>
            <p className="text-xs text-gray-500 ml-6">
              By checking this box, you agree to our{' '}
              <a href="https://www.usehana.com/privacy" target="_blank" className="text-blue-600 underline" rel="noopener noreferrer">
                Privacy Policy
              </a>
              , which outlines how we collect, use, and protect your personal information.
            </p>
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Checkbox id="microphone" required />
              <label htmlFor="microphone" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                {t('redirectToCall.microphone')} <span className="text-red-600">*</span>
              </label>
            </div>
            <p className="text-xs text-gray-500 ml-6">{t('redirectToCall.microphonePermission')}</p>
          </div>

          <div className="space-y-2">
            <div className="flex items-center space-x-2">
              <Checkbox id="medical" required />
              <label htmlFor="medical" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                {t('redirectToCall.medical')} <span className="text-red-600">*</span>
              </label>
            </div>
            <p className="text-xs text-gray-500 ml-6">{t('redirectToCall.medicalDisclaimer')}</p>
          </div>
        </div>

        <div className="flex justify-center mt-8">
          <Button type="submit" size="lg" disabled={loading} className="px-8 bg-black text-white">
            {loading ? t('redirectToCall.loading') : t('redirectToCall.button')}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default RedirectToCall;
