import { useState, useEffect } from 'react';
import { useUser, useAuth } from '@clerk/clerk-react';
import { Button } from '@material-tailwind/react';
import { CheckCircle2, ShieldCheck, HeadphonesIcon, Zap, Medal } from 'lucide-react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosProvider } from '../Components/AxiosProvider.jsx';
import { useAxios } from '../Components/axiosContext.js';

// Update the getDefaultFeatures function to access the features array
const getDefaultFeatures = (t) => t('pricing.defaultFeatures.features', { returnObjects: true });

const formatPeriod = (recurring) => {
  if (!recurring) return '';
  const { interval } = recurring;
  const count = recurring.interval_count || 1;

  if (count === 1) {
    return `per ${interval}`;
  }
  return `per ${count} ${interval}s`;
};

const formatPrice = (amount, currency) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency || 'USD',
    minimumFractionDigits: 2,
  }).format(amount / 100);
};

const FeatureCard = ({ icon: Icon, title, description }) => (
  <div className="p-4 rounded-lg bg-white border border-[#2B513B]/20 transform hover:scale-105 transition-all duration-300 hover:shadow-lg hover:shadow-[#2B513B]/10 group h-[140px] flex flex-col justify-center">
    <div className="flex items-start gap-3">
      <div className="p-2 rounded-lg bg-[#2B513B]/5 transition-colors duration-300 group-hover:bg-[#2B513B]/10">
        <Icon className="w-5 h-5 text-[#2B513B] stroke-[1.5] transition-transform duration-300 group-hover:scale-110" />
      </div>
      <div className="flex-1">
        <h3 className="text-base font-semibold mb-1.5 text-[#2B513B] line-clamp-1">{title}</h3>
        <p className="text-sm text-gray-600">{description}</p>
      </div>
    </div>
  </div>
);

FeatureCard.propTypes = {
  icon: PropTypes.elementType.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

const getButtonText = (isLoading, isSubscribed, t) => {
  if (isLoading) return t('pricing.buttonStates.processing');
  if (isSubscribed) return t('pricing.buttonStates.subscribed');
  return t('pricing.buttonStates.subscribe');
};

const PricingCard = ({ productId, onSelect, isLoading }) => {
  const { getToken } = useAuth();
  const [plan, setPlan] = useState(null);
  const [priceId, setPriceId] = useState(null);
  const [loadingPrices, setLoadingPrices] = useState(true);
  const [priceError, setPriceError] = useState(null);
  const [recurringPrice, setRecurringPrice] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const axios = useAxios();
  const { t } = useTranslation();

  useEffect(() => {
    const loadPrices = async () => {
      if (!productId) return;
      const token = await getToken();
      try {
        const data = await axios.get(`${import.meta.env.VITE_NODE_API_URL}/payments/prices/${productId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const { data: subscriptionData } = await axios.get('/payments/check-subscription');
        setIsSubscribed(subscriptionData.isValid);
        const { data: result } = data;
        const foundRecurringPrice = result.prices.type === 'recurring' ? result.prices : null;

        if (foundRecurringPrice) {
          setRecurringPrice(foundRecurringPrice);
          setPriceId(foundRecurringPrice.id);
          setPlan({
            name: result.product.name,
            description: result.product.description,
            price: foundRecurringPrice.unit_amount / 100,
            period: formatPeriod(foundRecurringPrice.recurring),
            features: result.product.metadata?.features ? JSON.parse(result.product.metadata.features) : getDefaultFeatures(t),
            productId: result.product.id,
          });
        } else {
          setPriceError('No recurring price found for this product');
        }
      } catch (err) {
        setPriceError('Failed to load pricing information');
      } finally {
        setLoadingPrices(false);
      }
    };

    loadPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId, getToken, t]);

  const handleSubscribeClick = () => {
    onSelect(plan, priceId);
  };

  if (loadingPrices) {
    return (
      <div className="p-6 max-w-lg mx-auto rounded-lg border border-[#2B513B]/20 bg-white">
        <div className="animate-pulse space-y-4 h-[540px]">
          <div className="h-8 bg-[#2B513B]/10 rounded w-1/3 mx-auto"></div>
          <div className="h-4 bg-[#2B513B]/10 rounded w-2/3 mx-auto"></div>
          <div className="h-20 bg-[#2B513B]/10 rounded"></div>
        </div>
      </div>
    );
  }

  if (priceError) {
    return (
      <div className="p-6 max-w-lg mx-auto rounded-lg border border-red-200 bg-red-50">
        <p className="text-red-600 text-center">{priceError}</p>
      </div>
    );
  }

  if (!plan) return null;

  return (
    <div className="p-6 max-w-lg mx-auto rounded-lg border border-[#2B513B]/20 bg-white transform hover:scale-105 transition-all duration-300 hover:shadow-xl hover:shadow-[#2B513B]/10 relative overflow-hidden group">
      <div className="text-center mb-6">
        <h3 className="text-2xl font-bold text-[#2B513B] mb-2">{plan.name}</h3>
        <p className="text-base text-gray-600 mb-3">{plan.description}</p>
        <div className="flex items-end justify-center gap-1">
          <span className="text-4xl font-bold text-[#2B513B]">{formatPrice(plan.price * 100, recurringPrice?.currency)}</span>
          <span className="text-base text-gray-600 mb-1">{plan.period}</span>
        </div>
      </div>

      <ul className="mb-6 space-y-3">
        {plan.features.map((feature) => (
          <li key={feature} className="flex items-center gap-2 text-gray-700">
            <CheckCircle2 className="w-4 h-4 text-[#2B513B] flex-shrink-0 stroke-[1.5] transition-transform duration-300 group-hover:scale-110" />
            <span className="text-base">{feature}</span>
          </li>
        ))}
      </ul>
      <Button
        color="gray"
        size="md"
        className="w-full py-3 text-base font-semibold bg-[#2B513B] hover:bg-[#234331] text-white rounded-full transition-all duration-300 transform group-hover:scale-105"
        disabled={isLoading || !priceId || isSubscribed}
        onClick={handleSubscribeClick}
      >
        {getButtonText(isLoading, isSubscribed, t)}
      </Button>
      <p className="text-center text-sm text-gray-500 mt-3">{t('pricing.securePayment')}</p>
    </div>
  );
};

PricingCard.propTypes = {
  productId: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

const PricingContent = () => {
  const { user } = useUser();
  const { getToken } = useAuth();
  const navigate = useNavigate();
  const axios = useAxios();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const handleSelectPlan = async (plan, priceId, promoCode) => {
    if (!user) {
      navigate('/signin');
      return;
    }
    setIsLoading(true);
    setError(null);

    try {
      const token = await getToken();
      if (!token) {
        throw new Error('Authentication token not available');
      }

      const { data } = await axios.post('/payments/create-checkout-session', {
        productId: plan.productId,
        priceId,
        promotionCode: promoCode ? promoCode.code : null,
      });

      if (!data?.url) {
        throw new Error('Failed to create checkout session');
      }

      // Redirect to Stripe Checkout
      window.location.href = data.url;
    } catch (err) {
      setError(err.message || 'An error occurred while processing your subscription');
      setIsLoading(false);
    }
  };

  const features = [
    {
      icon: Medal,
      title: t('pricing.features.provenResults.title'),
      description: t('pricing.features.provenResults.description'),
    },
    {
      icon: HeadphonesIcon,
      title: t('pricing.features.aiSupport.title'),
      description: t('pricing.features.aiSupport.description'),
    },
    {
      icon: ShieldCheck,
      title: t('pricing.features.safeSpace.title'),
      description: t('pricing.features.safeSpace.description'),
    },
    {
      icon: Zap,
      title: t('pricing.features.startToday.title'),
      description: t('pricing.features.startToday.description'),
    },
  ];

  return (
    <div className="min-h-screen bg-[#F5F8F6] py-12">
      <div className="py-12 px-4 sm:px-6 lg:px-8">
        <div className="max-w-6xl mx-auto">
          <div className="grid lg:grid-cols-2 gap-8 items-start">
            {/* Left Column - Content */}
            <div className="px-4">
              <div className="text-left mb-8">
                <h2 className="text-4xl font-extrabold mb-4 text-[#2B513B]">{t('pricing.journey')}</h2>
                <p className="text-lg text-gray-600">{t('pricing.subtitle')}</p>
                {error && (
                  <div className="mt-4 p-3 bg-red-50 text-red-700 rounded-lg border border-red-200">
                    <p className="font-medium text-sm">{error}</p>
                  </div>
                )}
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {features.map((feature) => (
                  <FeatureCard key={feature.title} icon={feature.icon} title={feature.title} description={feature.description} />
                ))}
              </div>
            </div>

            {/* Right Column - Pricing Card */}
            <div className="lg:sticky lg:top-4 px-4">
              <PricingCard productId={import.meta.env.VITE_STRIPE_PRODUCT_ID} onSelect={handleSelectPlan} isLoading={isLoading} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Pricing = () => {
  const [token, setToken] = useState(null);
  const { getToken, isSignedIn } = useAuth();

  useEffect(() => {
    const fetchToken = async () => {
      if (isSignedIn) {
        const newToken = await getToken();
        setToken(newToken);
      } else {
        setToken(null);
      }
    };
    fetchToken();
  }, [getToken, isSignedIn]);

  return (
    <AxiosProvider jwtToken={token}>
      <PricingContent />
    </AxiosProvider>
  );
};

export default Pricing;
