import { motion } from 'framer-motion';
import { Brain, Sun, Moon, MessageSquare, Flame, Heart, ChevronDown, AlertCircle } from 'lucide-react';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from './Collapsible.jsx';

// Unified color system for functional areas
const areaColors = {
  sleep: {
    background: '#FEF7CD',
    text: '#8B7355',
    icon: '#8B7355',
  },
  'cognitive function': {
    background: '#FEC6A1',
    text: '#8B4513',
    icon: '#8B4513',
  },
  'stress management': {
    background: '#FFDEE2',
    text: '#8B3E2F',
    icon: '#8B3E2F',
  },
  'emotional regulation': {
    background: '#FDE1D3',
    text: '#8B5742',
    icon: '#8B5742',
  },
  'energy level': {
    background: '#FFE4B5',
    text: '#8B7355',
    icon: '#8B7355',
  },
  'social support': {
    background: '#D3E4FD',
    text: '#2E5C8B',
    icon: '#2E5C8B',
  },
};

const getAreaColors = (title) => {
  const key = title.toLowerCase();
  return (
    areaColors[key] || {
      background: '#F1F0FB',
      text: '#6B7280',
      icon: '#6B7280',
    }
  );
};

const getAreaIcon = (title) => {
  const colors = getAreaColors(title);
  switch (title.toLowerCase()) {
    case 'sleep':
      return <Moon className="w-6 h-6" style={{ color: colors.icon }} />;
    case 'cognitive function':
      return <Brain className="w-6 h-6" style={{ color: colors.icon }} />;
    case 'stress management':
      return <Flame className="w-6 h-6" style={{ color: colors.icon }} />;
    case 'emotional regulation':
      return <Heart className="w-6 h-6" style={{ color: colors.icon }} />;
    case 'energy level':
      return <Sun className="w-6 h-6" style={{ color: colors.icon }} />;
    case 'social support':
      return <MessageSquare className="w-6 h-6" style={{ color: colors.icon }} />;
    default:
      return null;
  }
};

const getStatusColor = (status) => {
  switch (status.toLowerCase()) {
    case 'positive':
      return 'bg-[#90EE90]'; // Light green
    case 'mixed':
      return 'bg-[#FFE4B5]'; // Light yellow
    case 'concerning':
      return 'bg-[#FFB6B6]'; // Light red
    default:
      return 'bg-[#FEF7CD]';
  }
};

const getStatusTextColor = (status) => {
  switch (status.toLowerCase()) {
    case 'positive':
      return 'text-[#2E8B57]'; // Dark green
    case 'mixed':
      return 'text-[#8B7355]'; // Dark yellow
    case 'concerning':
      return 'text-[#8B3E2F]'; // Dark red
    default:
      return 'text-[#8B4513]';
  }
};

export const Report = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="container max-w-5xl py-12">
        {/* Welcome Section */}
        <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.6 }} className="mb-12 text-center">
          <div className="inline-block px-4 py-1 mb-4 text-sm font-medium text-report-text-secondary bg-report-muted rounded-full">{data?.userInfo?.date}</div>
          <h1 className="text-4xl font-bold mb-2 text-report-text-primary">{data?.welcome?.greeting}</h1>
          <p className="text-sm text-report-text-secondary mb-4">{data?.userInfo?.email}</p>
          <p className="text-lg text-report-text-secondary max-w-2xl mx-auto">{data?.welcome?.message}</p>
        </motion.div>

        {/* Overview Section */}
        <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6, delay: 0.2 }} className="mb-12">
          <h2 className="text-2xl font-semibold text-report-text-primary mb-6">Overview</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
            {data?.overview?.map((section, index) => (
              <motion.div
                key={section.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="bg-report-card-background p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow"
              >
                <h3 className="text-xl font-semibold mb-4 text-report-text-primary">{section.title}</h3>
                <ul className="space-y-3">
                  {section?.items?.map((item, itemIndex) => (
                    <li key={itemIndex} className="text-report-text-secondary flex items-start">
                      <span className="inline-block w-2 h-2 mt-2 mr-3 bg-report-accent rounded-full" />
                      {item}
                    </li>
                  ))}
                </ul>
              </motion.div>
            ))}
          </div>
        </motion.section>

        {/* Background Section */}
        <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6, delay: 0.3 }} className="mb-12">
          <h2 className="text-2xl font-semibold text-report-text-primary mb-6">Background</h2>
          <div className="bg-report-card-background p-6 rounded-xl shadow-sm">
            <ul className="space-y-3">
              {data?.background?.map((item, index) => (
                <li key={index} className="text-report-text-secondary flex items-start">
                  <span className="inline-block w-2 h-2 mt-2 mr-3 bg-[#FDE1D3] rounded-full" />
                  {item}
                </li>
              ))}
            </ul>
          </div>
        </motion.section>

        {/* Functional Areas Section */}
        <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6, delay: 0.4 }} className="mb-12">
          <h2 className="text-2xl font-semibold text-report-text-primary mb-6">Functional Areas Assessment</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {data?.functionalAreas?.map((area, index) => {
              const colors = getAreaColors(area.title);

              return (
                <motion.div
                  key={area.key}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                  className="bg-report-card-background p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow border"
                  style={{ borderColor: colors.background }}
                >
                  <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center gap-3">
                      {getAreaIcon(area.title)}
                      <h3 className="text-xl font-semibold" style={{ color: colors.text }}>
                        {area.title}
                      </h3>
                    </div>
                    <span className={`px-3 py-1 text-sm font-medium rounded-full ${getStatusColor(area.status)} ${getStatusTextColor(area.status)}`}>
                      {area.status}
                    </span>
                  </div>

                  <div className="mb-4">
                    <div className="flex items-center gap-1">
                      {[1, 2, 3, 4, 5].map((star) => (
                        <div key={star} className={`w-6 h-1 rounded ${star <= area.rating ? getStatusColor(area.status) : 'bg-gray-200'}`} />
                      ))}
                    </div>
                  </div>

                  <Collapsible open={isOpen} onOpenChange={setIsOpen}>
                    <CollapsibleTrigger className="flex items-center justify-between w-full py-2 text-sm font-medium text-report-text-secondary hover:text-report-text-primary transition-colors">
                      {isOpen ? 'Hide Details' : 'Show Details'}
                      <ChevronDown className={`w-4 h-4 transition-transform ${isOpen ? 'transform rotate-180' : ''}`} />
                    </CollapsibleTrigger>
                    <CollapsibleContent className="space-y-4 pt-4">
                      <div>
                        <h4 className="text-sm font-medium text-report-text-secondary mb-2">Observations</h4>
                        <ul className="space-y-2">
                          {area?.observations?.map((observation, i) => (
                            <li key={i} className="text-report-text-secondary text-sm flex items-start">
                              <span className="inline-block w-1.5 h-1.5 mt-1.5 mr-2 rounded-full" style={{ backgroundColor: colors.background }} />
                              {observation}
                            </li>
                          ))}
                        </ul>
                      </div>

                      <div>
                        <h4 className="text-sm font-medium text-report-text-secondary mb-2">Evidence</h4>
                        <p className="text-report-text-secondary text-sm italic">&quot;{area.evidence}&quot;</p>
                      </div>
                    </CollapsibleContent>
                  </Collapsible>
                </motion.div>
              );
            })}
          </div>
        </motion.section>

        {/* Recommendations Section */}
        <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6, delay: 0.6 }} className="mb-12">
          <h2 className="text-2xl font-semibold text-report-text-primary mb-6">Recommendations</h2>
          <div className="grid grid-cols-1 gap-6">
            {data?.recommendations?.map((recommendation, index) => {
              const getPriorityColor = (priority) => {
                switch (priority.toLowerCase()) {
                  case 'high':
                    return 'bg-[#E74C3C]';
                  case 'medium':
                    return 'bg-[#F97316]';
                  case 'low':
                    return 'bg-[#FEC6A1]';
                  default:
                    return 'bg-[#FEF7CD]';
                }
              };

              const getAreaColor = (area) => {
                return getAreaColors(area);
              };

              const toSentenceCase = (str) => {
                return str
                  .replace(/([A-Z])/g, ' $1')
                  .replace(/^./, (char) => char.toUpperCase())
                  .trim();
              };

              return (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.6, delay: index * 0.1 }}
                  className="bg-report-card-background p-6 rounded-xl shadow-sm hover:shadow-md transition-shadow border border-[#FDE1D3]"
                >
                  <div className="flex justify-between items-center mb-4">
                    <h3 className="text-xl font-semibold text-report-text-primary">{recommendation.title}</h3>
                    <span className={`px-3 py-1 text-sm font-medium text-white rounded-full ${getPriorityColor(recommendation.priority)}`}>
                      {recommendation.priority} Priority
                    </span>
                  </div>

                  <p className="text-report-text-secondary mb-4">{recommendation.description}</p>

                  <div className="space-y-4">
                    <div>
                      <h4 className="text-sm font-medium text-report-text-secondary mb-2">Steps to Take</h4>
                      <ul className="space-y-2">
                        {recommendation?.steps?.map((step, i) => (
                          <li key={i} className="text-report-text-secondary text-sm flex items-start">
                            <span className="inline-block w-6 text-center mr-2">{i + 1}.</span>
                            {step}
                          </li>
                        ))}
                      </ul>
                    </div>

                    <div>
                      <h4 className="text-sm font-medium text-report-text-secondary mb-2">Related Areas</h4>
                      <div className="flex flex-wrap gap-2">
                        {recommendation?.relatedAreas?.map((area, i) => {
                          const colors = getAreaColor(toSentenceCase(area));
                          return (
                            <span
                              key={i}
                              className="px-3 py-1 text-sm rounded-full transition-colors"
                              style={{
                                backgroundColor: colors.background,
                                color: colors.text,
                              }}
                            >
                              {toSentenceCase(area)}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </motion.div>
              );
            })}
          </div>
        </motion.section>

        {/* Next Discussion Section */}
        <motion.section initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.6, delay: 0.8 }}>
          <h2 className="text-2xl font-semibold text-report-text-primary mb-6">Next Discussion</h2>
          <div className="bg-report-card-background p-6 rounded-xl shadow-sm">
            <ul className="space-y-4">
              {data?.nextDiscussion?.map((question, index) => (
                <li key={index} className="text-report-text-secondary flex items-start">
                  <span className="inline-block w-2 h-2 mt-2 mr-3 bg-report-accent rounded-full" />
                  {question}
                </li>
              ))}
            </ul>
          </div>
        </motion.section>

        {/* Medical Disclaimer */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.6, delay: 1 }}
          className="mt-12 bg-white rounded-xl shadow-sm p-6 flex items-start gap-4"
        >
          <AlertCircle className="w-6 h-6 text-gray-500 flex-shrink-0 mt-1" />
          <p className="text-gray-600 text-sm">
            This report reflects our conversation and is designed to help you understand patterns in your experience. It is not a medical diagnosis. If you have
            concerns, please consult with a healthcare provider.
          </p>
        </motion.div>
      </div>
    </div>
  );
};

Report.propTypes = {
  data: PropTypes.shape({
    userInfo: PropTypes.shape({
      date: PropTypes.string,
      email: PropTypes.string,
    }).isRequired,
    welcome: PropTypes.shape({
      greeting: PropTypes.string,
      message: PropTypes.string,
    }).isRequired,
    overview: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.arrayOf(PropTypes.string),
      }),
    ).isRequired,
    background: PropTypes.arrayOf(PropTypes.string).isRequired,
    functionalAreas: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        title: PropTypes.string,
        status: PropTypes.string,
        rating: PropTypes.number,
        observations: PropTypes.arrayOf(PropTypes.string),
        evidence: PropTypes.string,
      }),
    ).isRequired,
    recommendations: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        priority: PropTypes.string,
        description: PropTypes.string,
        steps: PropTypes.arrayOf(PropTypes.string),
        relatedAreas: PropTypes.arrayOf(PropTypes.string),
      }),
    ).isRequired,
    nextDiscussion: PropTypes.arrayOf(PropTypes.string).isRequired,
  }).isRequired,
};
