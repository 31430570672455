import { useTranslation } from 'react-i18next';
import { Button } from './ui/button.jsx';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './ui/accordion.jsx';

const FAQ = () => {
  const { t } = useTranslation();

  return (
    <section id="faq" className="py-20 bg-white">
      <div className="container mx-auto px-6">
        <div className="text-center mb-16">
          <h2 className="text-3xl font-heading text-[#2B513B] mb-4">{t('landing.faq.title')}</h2>
          <p className="text-gray-600 max-w-2xl mx-auto font-sans">{t('landing.faq.subtitle')}</p>
        </div>
        <div className="max-w-3xl mx-auto mb-16">
          <Accordion type="single" collapsible className="w-full">
            {t('landing.faq.questions', { returnObjects: true }).map((item, index) => (
              <AccordionItem key={index} value={`item-${index}`} className="border-b border-[#2B513B]/20">
                <AccordionTrigger className="text-lg font-heading text-[#2B513B] hover:no-underline">{item.question}</AccordionTrigger>
                <AccordionContent className="text-gray-600 font-sans">{item.answer}</AccordionContent>
              </AccordionItem>
            ))}
          </Accordion>
        </div>
        <div className="text-center">
          <h3 className="text-2xl font-heading text-[#2B513B] mb-4">{t('landing.faq.contact.title')}</h3>
          <p className="text-gray-600 mb-8 font-sans">{t('landing.faq.contact.description')}</p>
          <Button
            variant="outline"
            className="px-8 py-6 text-lg border-2 border-[#2B513B] text-[#2B513B] hover:bg-[#2B513B] hover:text-white rounded-full font-heading"
          >
            {t('landing.faq.contact.button')}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
