import { useContext } from 'react';
import PropTypes from 'prop-types';
import { createBrowserRouter, Outlet, RouterProvider, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@material-tailwind/react';
import { ClerkProvider, SignedOut, useAuth, useUser } from '@clerk/clerk-react';
import Start from './Pages/Start.jsx';
import Call from './Pages/Call.jsx';
import Report from './Pages/Report.jsx';
import AuthedLayout from './AuthedLayout.jsx';
import SignIn from './Pages/SignIn.jsx';
import Login from './Pages/Login.jsx';
import Dashboard from './NewDashboard/index.jsx';
import VerifyEmail from './Components/verifyEmail.jsx';
import Followup from './Pages/Followup.jsx';
import RedirectToCall from './Components/RedirectToCall.jsx';
import Callback from './Pages/callback.tsx';
import Navbar from './Components/Navbar.jsx';
import Pricing from './Pages/Pricing.jsx';
import PaymentSuccess from './Pages/PaymentSuccess.jsx';
import NotFound from './Pages/NotFound.jsx';
import { AxiosProvider } from './Components/AxiosProvider.jsx';
import Loading from './Components/Loading.jsx';
import SubscriptionGuard from './Components/SubscriptionGuard.jsx';
import { Sidebar } from './Dashboard/Container.jsx';
import { RootContext, RootContextProvider } from './rootContext.jsx';
import OrganizationManagement from './Pages/OrganizationManagement.jsx';
import OrganizationInvite from './Pages/OrganizationInvite.jsx';
import { useClerkLocalization } from './hooks/useClerkLocalization.js';
import { SyncActiveOrganizationFromURLToSession } from './hooks/setOrganisation.jsx';
import TawkToChat from './Components/TawkToChat.jsx';
import { useReportId } from './hooks/useReportId.js';

const RootLayout = () => {
  const { user } = useUser();
  const { setIsSidebarOpen } = useContext(RootContext);

  const toggleSidebar = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <div className="min-h-screen bg-[#E8E7E7]">
      <Navbar user={user} toggleSidebar={toggleSidebar} />
      <Outlet />
    </div>
  );
};

const Container = () => {
  return (
    <div className="p-4 sm:p-8">
      <main className="max-w-4xl mx-auto bg-white rounded-3xl shadow-2xl overflow-hidden">
        <SignedOut>
          <Navigate to={'/landing'} replace />
        </SignedOut>
        <Outlet />
      </main>
    </div>
  );
};

const DashboardLayout = () => {
  const { isSidebarOpen, language } = useContext(RootContext);
  const { isLoaded, userId } = useAuth();
  const { reportId, isLoading } = useReportId();

  if (!isLoaded || isLoading) return <Loading />;
  if (!userId) return <Navigate to="/signin" replace />;

  return (
    <div className="flex flex-col h-screen relative bg-[#F5F8F6]">
      <div className="flex flex-1 overflow-hidden pt-10">
        <Sidebar reportId={reportId} isOpen={isSidebarOpen} language={language} />
        <main className="flex-1 overflow-auto p-4 bg-[#F5F8F6]">
          <Outlet />
        </main>
      </div>
    </div>
  );
};

const AppContent = () => {
  const { isLoaded, isSignedIn } = useAuth();

  if (!isLoaded) {
    return <Loading />;
  }

  const theme = {
    button: {
      styles: {
        base: {
          initial: {
            textTransform: 'none',
          },
        },
        sizes: {
          sm: {
            fontSize: 'text-base',
          },
          md: {
            fontSize: 'text-xl',
          },
        },
      },
    },
    input: {
      defaultProps: {
        size: 'lg',
      },
    },
  };

  // Create a wrapper for protected routes that need auth
  const ProtectedRoute = ({ children }) => {
    if (!isSignedIn) {
      return <Navigate to="/signin" replace />;
    }
    return <AxiosProvider>{children}</AxiosProvider>;
  };

  ProtectedRoute.propTypes = {
    children: PropTypes.node.isRequired,
  };

  const routerConfig = createBrowserRouter([
    {
      path: '/',
      element: <RootLayout />,
      children: [
        {
          path: '/auth',
          element: (
            <ProtectedRoute>
              <DashboardLayout />
            </ProtectedRoute>
          ),
          children: [
            {
              path: 'dashboard',
              element: (
                <SubscriptionGuard>
                  <Dashboard />
                </SubscriptionGuard>
              ),
            },
            {
              path: 'report/:reportId',
              element: (
                <ProtectedRoute>
                  <Report />
                </ProtectedRoute>
              ),
            },
            {
              path: 'followup',
              element: (
                <SubscriptionGuard>
                  <Followup />
                </SubscriptionGuard>
              ),
            },
            {
              path: 'organization-management',
              element: <OrganizationManagement />,
            },
          ],
        },
        {
          path: '/landing',
          element: <Callback />,
        },
        {
          path: '/pricing',
          element: <Pricing />,
        },
        {
          path: '/',
          element: <Container />,
          children: [
            {
              path: 'start',
              element: <Start />,
            },
            {
              path: 'verify-email',
              element: <VerifyEmail />,
            },
            {
              path: 'report/:reportId',
              element: <Report />,
            },
            {
              path: 'signin',
              element: <SignIn />,
            },
            {
              path: 'login',
              element: <Login />,
            },
            {
              element: <AuthedLayout />,
              path: 'auth',
              children: [
                { path: 'call', element: <Call /> },
                {
                  path: 'report',
                  element: (
                    <ProtectedRoute>
                      <RedirectToCall />
                    </ProtectedRoute>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: 'payments/success',
          element: (
            <ProtectedRoute>
              <PaymentSuccess />,
            </ProtectedRoute>
          ),
        },
        {
          path: '/invite/:slug',
          element: <OrganizationInvite />,
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ]);

  return (
    <ThemeProvider value={theme}>
      <RouterProvider router={routerConfig} />
    </ThemeProvider>
  );
};

const App = () => {
  const localization = useClerkLocalization();
  return (
    <ClerkProvider publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY} afterSignOutUrl="/" localization={localization}>
      <RootContextProvider>
        <SyncActiveOrganizationFromURLToSession />
        <AppContent />
        <TawkToChat />
      </RootContextProvider>
    </ClerkProvider>
  );
};

export default App;
