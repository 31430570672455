import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const NotFound = () => {
  const { t } = useTranslation();
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({
        x: (e.clientX / window.innerWidth - 0.5) * 20,
        y: (e.clientY / window.innerHeight - 0.5) * 20,
      });
    };

    window.addEventListener('mousemove', handleMouseMove);
    return () => window.removeEventListener('mousemove', handleMouseMove);
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center bg-white overflow-hidden relative">
      <div
        className="text-center p-8 bg-white rounded-2xl shadow-2xl max-w-md w-full mx-4 animate-fade-up relative z-10 transform transition-transform duration-300 hover:scale-105"
        style={{
          transform: `translate(${mousePosition.x}px, ${mousePosition.y}px)`,
        }}
      >
        <div className="mb-8 relative">
          <h1 className="text-9xl font-bold text-emerald-600 mb-2 relative z-10">{t('notFound.title')}</h1>
          <div className="w-24 h-24 mx-auto mb-4 relative">
            <svg
              className="w-full h-full text-emerald-500 relative z-10 transform transition-transform duration-300 hover:rotate-12"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
        </div>

        <h2 className="text-2xl font-semibold text-gray-800 mb-4 animate-fade-up animation-delay-200">{t('notFound.heading')}</h2>
        <p className="text-gray-600 mb-8 animate-fade-up animation-delay-400">{t('notFound.message')}</p>

        <Link
          to="/"
          className="group inline-block px-6 py-3 bg-emerald-600 text-white rounded-lg hover:bg-emerald-700 transition-all duration-300 ease-in-out shadow-md hover:shadow-lg transform hover:-translate-y-1 relative overflow-hidden"
        >
          <span className="relative z-10 flex items-center">
            {t('notFound.backHome')}
            <svg
              className="w-5 h-5 ml-2 transform transition-transform duration-300 group-hover:translate-x-1"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
            </svg>
          </span>
          <div className="absolute inset-0 bg-emerald-500 transform scale-x-0 group-hover:scale-x-100 transition-transform duration-300 origin-left"></div>
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
