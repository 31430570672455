import React from 'react';
import { useTranslation } from 'react-i18next';
import { LineChart, Lock, Smartphone, Trophy } from 'lucide-react';

const Features = () => {
  const { t } = useTranslation();

  const features = [
    {
      title: t('landing.features.items.ai.title'),
      description: t('landing.features.items.ai.description'),
      position: 'right',
      icon: Lock,
    },
    {
      title: t('landing.features.items.tracking.title'),
      description: t('landing.features.items.tracking.description'),
      position: 'right',
      icon: Smartphone,
    },
    {
      title: t('landing.features.items.journal.title'),
      description: t('landing.features.items.journal.description'),
      position: 'left',
      icon: LineChart,
    },
    {
      title: t('landing.features.items.motivation.title'),
      description: t('landing.features.items.motivation.description'),
      position: 'left',
      icon: Trophy,
    },
  ];

  return (
    <div id="features" className="pb-24 bg-[#F5F8F6] px-6 md:px-12">
      <div className="text-center mb-16">
        <h2 className="text-3xl font-heading text-[#2B513B] mb-4">{t('landing.features.title')}</h2>
        <p className="text-gray-600 max-w-2xl mx-auto font-sans">{t('landing.features.subtitle')}</p>
      </div>
      <div className="flex flex-col lg:grid lg:grid-cols-3 gap-16 lg:gap-20 items-center">
        {/* Left features */}
        <div className="flex flex-col space-y-12 w-full">
          {features.slice(0, 2).map((feature, index) => (
            <div key={index} className="flex flex-col items-center text-center md:items-end md:text-right space-y-3 w-full">
              <div className="w-14 h-14 flex items-center justify-center bg-[#f1eaba] rounded-md mb-4">
                {React.createElement(feature.icon, {
                  className: 'w-7 h-7 text-black',
                })}
              </div>
              <h3 className="text-xl font-heading text-[#2B513B]">{feature.title}</h3>
              <p className="text-gray-600 text-sm leading-relaxed font-sans">{feature.description}</p>
            </div>
          ))}
        </div>

        {/* Center phone */}
        <div className="flex justify-center items-center w-full">
          <div className="relative w-[280px] sm:w-[320px] h-[520px] sm:h-[600px] bg-black rounded-[2.5rem] p-4 shadow-2xl">
            <div className="absolute top-0 left-1/2 transform -translate-x-1/2 w-36 sm:w-40 h-7 bg-black rounded-b-2xl z-10"></div>
            <div className="relative w-full h-full overflow-hidden rounded-[2rem] bg-black">
              <iframe
                src="https://player.vimeo.com/video/1057271637?autoplay=1&loop=1&controls=1&muted=1"
                className="absolute inset-0 w-full h-full"
                allow="autoplay; fullscreen; picture-in-picture"
                frameBorder="0"
                title="App demo video"
              />
            </div>
          </div>
        </div>

        {/* Right features */}
        <div className="flex flex-col space-y-12 w-full">
          {features.slice(2).map((feature, index) => (
            <div key={index} className="flex flex-col items-center text-center md:items-start md:text-left space-y-3 w-full">
              <div className="w-14 h-14 flex items-center justify-center bg-[#f1eaba] rounded-md mb-4">
                {React.createElement(feature.icon, {
                  className: 'w-7 h-7 text-black',
                })}
              </div>
              <h3 className="text-xl font-heading text-[#2B513B]">{feature.title}</h3>
              <p className="text-gray-600 text-sm leading-relaxed font-sans">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
