import { useEffect, useState } from 'react';
import { RedirectToSignIn, RedirectToSignUp, useAuth, UserButton } from '@clerk/clerk-react';
import { useNavigate, useLocation } from 'react-router-dom';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from './ui/button.jsx';
import LoadingSpinner from './LoadingSpinner.jsx';
import LanguageSelector from './LanguageSelector.jsx';
import OrganizationManager from './OrganizationManager.jsx';

const Navbar = ({ user, toggleSidebar, isLoading }) => {
  const { isLoaded } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const [redirectToSignUp, setRedirectToSignUp] = useState(false);
  const [redirectToSignIn, setRedirectToSignIn] = useState(false);
  const [loadingSignIn, setLoadingSignIn] = useState(false);
  const [loadingSignUp, setLoadingSignUp] = useState(false);
  const [isPricingFlow, setIsPricingFlow] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    // Only redirect to dashboard if we're on the landing page or root path
    // and not currently loading
    if (user?.id && !isLoading && (location.pathname === '/landing' || location.pathname === '/')) {
      const reportId = user?.unsafeMetadata?.reportId;
      if (reportId === undefined || reportId === '') {
        navigate('/auth/report');
      } else {
        navigate(`/auth/report/${reportId}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, location.pathname, isLoading]);

  useEffect(() => {
    const handleLanguageChange = () => {
      // window.location.reload();
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  if (redirectToSignUp) {
    return <RedirectToSignUp redirectUrl={isPricingFlow ? '/pricing' : '/'} />;
  }
  if (redirectToSignIn) {
    return <RedirectToSignIn redirectUrl={isPricingFlow ? '/pricing' : '/'} />;
  }

  const handleSignUp = (fromPricing = false) => {
    setLoadingSignUp(true);
    setIsPricingFlow(fromPricing);
    setRedirectToSignUp(true);
  };

  const handleSignIn = (fromPricing = false) => {
    setLoadingSignIn(true);
    setIsPricingFlow(fromPricing);
    setRedirectToSignIn(true);
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      setMenuOpen(false); // Close menu after clicking
    }
  };

  if (!isLoaded) {
    return (
      <nav className="fixed top-0 left-0 right-0 bg-white/30 backdrop-blur-md z-[999] border-b">
        <div className="container mx-auto px-6 h-16 flex items-center justify-center">
          <LoadingSpinner className="h-6 w-6" />
        </div>
      </nav>
    );
  }

  const NavLinks = () => (
    <>
      <Button variant="ghost" className="text-black hover:bg-gray-100" onClick={() => scrollToSection('features')}>
        {t('navigation.features')}
      </Button>
      <Button variant="ghost" className="text-black hover:bg-gray-100" onClick={() => scrollToSection('faq')}>
        {t('navigation.faq')}
      </Button>
      <Button variant="ghost" className="text-black hover:bg-gray-100" onClick={() => handleSignIn(true)}>
        {t('navigation.pricing')}
      </Button>
    </>
  );

  const AuthButtons = () => (
    <>
      <Button variant="ghost" className="text-black hover:bg-gray-100" onClick={() => handleSignIn(false)} disabled={loadingSignIn}>
        {loadingSignIn ? (
          <div className="flex items-center gap-2">
            <LoadingSpinner className="h-4 w-4" />
            <span>{t('navigation.loading')}</span>
          </div>
        ) : (
          t('navigation.login')
        )}
      </Button>
      <Button variant="default" className="bg-[#2B513B] text-white hover:bg-[#234331]" onClick={() => handleSignUp(false)} disabled={loadingSignUp}>
        {loadingSignUp ? (
          <div className="flex items-center gap-2">
            <LoadingSpinner className="h-4 w-4" />
            <span>{t('navigation.loading')}</span>
          </div>
        ) : (
          t('navigation.signup')
        )}
      </Button>
    </>
  );

  return (
    <nav className="fixed top-0 left-0 right-0 bg-white/30 backdrop-blur-md z-[999] border-b">
      <div className="mx-auto px-6 h-16 flex items-center justify-between relative">
        <div className="flex items-center gap-4">
          {!user && (
            <button className="p-1 w-8 h-8 bg-white-500 text-teal-500 rounded-md shadow-md lg:hidden" onClick={() => setMenuOpen(!menuOpen)}>
              ☰
            </button>
          )}
          {user && location?.pathname !== '/auth/report' && location?.pathname !== '/pricing' && (
            <button className="p-1 w-8 h-8 bg-white text-teal-500 rounded-md shadow-md md:hidden" onClick={toggleSidebar}>
              ☰
            </button>
          )}

          <div
            className="h-12 cursor-pointer"
            onClick={() => {
              if (!user) {
                navigate('/');
                return;
              }
              const reportId = user?.unsafeMetadata?.reportId;
              if (reportId === undefined || reportId === '') {
                navigate('/auth/report');
                return;
              }
              navigate(`/auth/report/${reportId}`);
            }}
          >
            <img
              src="https://cdn.prod.website-files.com/66d8df543029dd65661ce227/67ac8c50f043cf48b1a2116d_logoHana-p-500.png"
              alt="Hana Logo"
              className="h-full object-contain sm:w-24 md:w-28"
            />
          </div>
        </div>

        {!user && (
          <div className="hidden lg:flex items-center justify-center flex-1 gap-4">
            <NavLinks />
          </div>
        )}

        {!user ? (
          <div className="flex items-center gap-2 lg:gap-4">
            <LanguageSelector />
            <AuthButtons />
          </div>
        ) : (
          <div className="flex items-center justify-end space-x-4">
            <LanguageSelector />
            <OrganizationManager />
            <div className="sm:flex items-center space-x-3">
              <div className="hidden sm:flex flex-col items-end">
                <p className="font-medium text-gray-700 text-sm sm:text-base">{user?.fullName}</p>
              </div>
              <UserButton
                i18nIsDynamicList
                appearance={{
                  elements: {
                    avatarBox: 'w-8 h-8',
                  },
                }}
              />
            </div>
          </div>
        )}
      </div>

      {/* Mobile menu */}
      {!user && menuOpen && (
        <div className="absolute top-16 left-0 right-0 bg-white shadow-md p-4 flex flex-col space-y-2 lg:hidden">
          <NavLinks />
        </div>
      )}
    </nav>
  );
};

Navbar.propTypes = {
  user: propTypes.object,
  toggleSidebar: propTypes.func,
  isLoading: propTypes.bool,
};

export default Navbar;
