import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';
import { useTranslation } from 'react-i18next';
import { Building } from 'lucide-react';
import PropTypes from 'prop-types';

const OrganizationManager = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useUser();

  const organization = user?.organizationMemberships.length > 0 ? user?.organizationMemberships[0] : null;
  if (!organization || !organization?.organization?.id) return null;
  // Check if the user is an admin of the organization
  const isOrgAdmin = organization?.role === 'org:admin';

  return (
    <button
      disabled={!isOrgAdmin}
      onClick={() => navigate('/auth/organization-management')}
      className="flex items-center lg:gap-2 lg:px-3 text-sm font-medium text-gray-700hover:bg-gray-50 transition-colors"
    >
      {organization?.organization?.imageUrl ? (
        <img src={organization?.organization?.imageUrl} alt={organization?.organization?.name} className="h-6 w-6 rounded-full" />
      ) : (
        <Building className="h-4 w-4 text-teal-400" />
      )}
      <span className="hidden sm:inline">{organization?.organization?.name || t('navigation.organizations')}</span>
    </button>
  );
};

OrganizationManager.propTypes = {
  isAdmin: PropTypes.bool,
};

export default OrganizationManager;
