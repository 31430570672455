'use client';

import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth, useOrganizationList, useUser } from '@clerk/clerk-react';

export function SyncActiveOrganizationFromURLToSession() {
  const { setActive, isLoaded } = useOrganizationList();

  // Get the organization slug from the session and user data
  const { orgSlug } = useAuth();
  const { user } = useUser();

  // Get the latest organization from user's organization memberships - using useMemo to stabilize the reference
  const latestOrganization = useMemo(() => {
    return user?.organizationMemberships?.length > 0 ? user?.organizationMemberships[0] : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.organizationMemberships.length]);

  // Get the organization slug from the URL
  // e.g. https://example.com/orgSlug/<your-org-slug>
  const params = useParams();
  const urlOrgSlug = params ? params.orgSlug : undefined;

  useEffect(() => {
    if (!isLoaded) return;

    // Set the active organization to the first one in the user's memberships
    if (latestOrganization && latestOrganization.organization) {
      setActive({ organization: latestOrganization.organization.id });
    }
    // Fallback to URL slug if available and no memberships found
    else if (urlOrgSlug && urlOrgSlug !== orgSlug) {
      setActive({ organization: urlOrgSlug });
    }
  }, [isLoaded, setActive, latestOrganization, urlOrgSlug, orgSlug]);

  return null;
}
