import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CheckCircle, AlertTriangle, XCircle, X } from 'lucide-react';

const Toast = ({ message, duration = 2000, variant = 'success', setShowToast }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    setVisible(true);

    const timer = setTimeout(() => {
      setVisible(false);
      setTimeout(() => setShowToast(false), 500);
    }, duration);

    return () => clearTimeout(timer);
  }, [duration, setShowToast]);

  // Define styles & icons based on variant
  const variantStyles = {
    success: {
      bg: 'bg-green-600', // Solid Green Background
      icon: <CheckCircle className="w-5 h-5 text-green-500" />,
      border: 'border-l-4 border-green-400',
    },
    error: {
      bg: 'bg-red-600', // Solid Red Background
      icon: <XCircle className="w-5 h-5 text-red-500" />,
      border: 'border-l-4 border-red-400',
    },
    warning: {
      bg: 'bg-yellow-500', // Solid Yellow Background
      icon: <AlertTriangle className="w-5 h-5 text-yellow-500" />,
      border: 'border-l-4 border-yellow-400',
    },
  };

  return (
    <div
      className={`fixed bottom-5 right-4 max-w-sm flex items-center gap-3 px-5 py-3 rounded-lg transition-transform duration-500 ease-in-out transform ${
        visible ? 'translate-x-0 opacity-100' : 'translate-x-20 opacity-0'
      } ${variantStyles[variant].bg} ${variantStyles[variant].border} shadow-lg`}
      style={{
        color: '#fff',
        transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
      }}
    >
      {variantStyles[variant].icon}
      <p className="text-sm font-semibold">{message}</p>
      <button className="ml-auto p-1 text-white/80 hover:text-white" onClick={() => setVisible(false)}>
        <X className="w-4 h-4" />
      </button>
    </div>
  );
};

Toast.propTypes = {
  message: PropTypes.string.isRequired,
  duration: PropTypes.number,
  variant: PropTypes.oneOf(['error', 'warning', 'success']),
  setShowToast: PropTypes.func.isRequired,
};

export default Toast;
