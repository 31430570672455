import { useState, useEffect } from 'react';
import { RedirectToSignUp } from '@clerk/clerk-react';
import { useTranslation } from 'react-i18next';
import { Input } from './ui/input.jsx';
import { Button } from './ui/button.jsx';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './ui/select.jsx';
import Toast from './Toast.jsx';

const countries = [
  {
    code: 'IN',
    name: 'India',
    flag: '🇮🇳',
    dialCode: '+91',
  },
  {
    code: 'US',
    name: 'United States',
    flag: '🇺🇸',
    dialCode: '+1',
  },
  {
    code: 'AU',
    name: 'Australia',
    flag: '🇦🇺',
    dialCode: '+61',
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    flag: '🇳🇿',
    dialCode: '+64',
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    flag: '🇬🇧',
    dialCode: '+44',
  },
  {
    code: 'IE',
    name: 'Ireland',
    flag: '🇮🇪',
    dialCode: '+353',
  },
];

const CallToAction = () => {
  const { t } = useTranslation();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [countryCode, setCountryCode] = useState('US');
  const [redirectToSignUp, setRedirectToSignUp] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({
    message: '',
    variant: 'success',
  });

  const selectedCountry = countries.find((c) => c.code === countryCode);

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch(`https://f0rxxj240j.execute-api.eu-west-1.amazonaws.com/v1/call`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone_number: (selectedCountry?.dialCode || 0) + phoneNumber,
      }),
    });
    setToastMessage({
      message: 'Call requested successfully',
      variant: 'success',
    });
    setShowToast(true);
  };

  useEffect(() => {
    return () => {
      setRedirectToSignUp(false);
    };
  }, []);

  if (redirectToSignUp) {
    return <RedirectToSignUp />;
  }

  return (
    <section className="py-24 bg-[#F5F8F6]">
      <div className="container mx-auto px-6 font-playfair">
        <div className="max-w-6xl mx-auto">
          {/* Hero Section */}
          <div className="text-center max-w-3xl mx-auto mb-20">
            <h1 className="text-xl lg:text-6xl font-heading mb-6 text-[#2B513B]">{t('landing.hero.title')}</h1>
            <p className="font-sans text-[#2B513B] text-xl mb-8 flex items-center justify-center gap-2">
              <span className="text-2xl">✨</span> {t('landing.hero.subtitle')}
            </p>
            {/* Form */}
            <div className="max-w-md mx-auto">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex gap-4">
                  <Select value={countryCode} onValueChange={setCountryCode}>
                    <SelectTrigger className="w-[140px] h-12 bg-white">
                      <SelectValue>
                        <span className="flex items-center gap-2">
                          <span>{selectedCountry?.flag}</span>
                          <span>{selectedCountry?.dialCode}</span>
                        </span>
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent className="bg-white">
                      {countries.map((country) => (
                        <SelectItem key={country.code} value={country.code} className="flex items-center gap-2">
                          <span className="flex items-center gap-2">
                            <span>{country.flag}</span>
                            <span className="font-sans">{country.name}</span>
                            <span className="text-gray-500">{country.dialCode}</span>
                          </span>
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Input
                    type="tel"
                    placeholder={t('landing.hero.phoneNumber')}
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="flex-1 h-12 bg-white font-sans"
                    required
                  />
                </div>
                <div className="flex gap-4">
                  <Button
                    type="submit"
                    className="flex-1 py-6 text-lg bg-[#2B513B] hover:bg-[#234331] border-2 border-[#2B513B] text-white rounded-full font-heading"
                  >
                    {t('landing.hero.cta')}
                  </Button>
                  <Button
                    type="button"
                    className="flex-1 py-6 text-lg bg-white text-[#2B513B] hover:bg-gray-50 border-2 border-[#2B513B] rounded-full font-heading"
                    onClick={() => {
                      setRedirectToSignUp(true);
                    }}
                  >
                    {t('navigation.signup')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {showToast && <Toast message={toastMessage.message} variant={toastMessage.variant} setShowToast={setShowToast} />}
    </section>
  );
};

export default CallToAction;
