import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { enUS, itIT, ukUA } from '@clerk/localizations';

/**
 * Custom hook to provide Clerk localization based on the current i18n language
 * @returns {Object} Clerk localization object
 */
export const useClerkLocalization = () => {
  const { i18n } = useTranslation();

  const localization = useMemo(() => {
    // Map i18n language codes to Clerk localization objects
    const localizationMap = {
      en: enUS,
      it: itIT,
      uk: ukUA,
      // Add more languages as needed
    };

    // Get the base language code (e.g., 'en' from 'en-US')
    const baseLanguage = i18n.language.split('-')[0];

    // Return the appropriate localization or fall back to English
    return localizationMap[baseLanguage] || enUS;
  }, [i18n.language]);

  return localization;
};

export default useClerkLocalization;
