/**
 * Utilities for Tawk.to integration
 */

import HmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';

/**
 * The Tawk.to API key from your dashboard.
 * In production, this should be stored in environment variables.
 * Admin > Property Settings > API Key
 */
const TAWK_API_KEY = import.meta.env.VITE_TAWK_API_KEY || '';

/**
 * Generates an HMAC SHA256 hash for Tawk.to secure mode
 *
 * @param {string} email - The visitor's email
 * @returns {string} HMAC SHA256 hash
 */
export function generateTawkHash(email) {
  if (!email || !TAWK_API_KEY) {
    // eslint-disable-next-line no-console
    console.warn('Missing email or Tawk API key for generating secure hash');
    return '';
  }

  try {
    const hash = HmacSHA256(email, TAWK_API_KEY);
    return Base64.stringify(hash);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error generating Tawk hash:', error);
    return '';
  }
}
